export const getCustomerResult = (res) => res.data && res.data.customer;

export const createCustomerResult = (res) =>
  res.data && res.data.createCustomer;

export const getLanguagesResult = (res) => res.data && res.data.languages;

export const getBranchesResult = (res) => res.data && res.data.branches;

export const updateCustomerResult = (res) =>
  res.data && res.data.updateCustomer;

export const getAccentColorsResult = (res) => res.data && res.data.accentColors;

export const getCustomersResult = (res) => res.data && res.data.customers;

export const getEmployeesCountsResult = (res) =>
  res.data && res.data.employeesCounts;

export const getSurveyResult = (res) => res.data && res.data.survey;

export const updateSurveyInfoTranslationResult = (res) =>
  res.data && res.data.updateSurveyInfoTranslation;

export const updateQuestionTranslationsResult = (res) =>
  res.data && res.data.updateQuestionTranslations;

export const getSurveyByLinkResult = (res) => res.data && res.data.surveyByLink;

export const createAnswersResult = (res) => res.data && res.data.createAnswers;

export const signUpResult = (res) => res.data && res.data.signUp;

export const signInResult = (res) => res.data && res.data.signIn;

export const authenticateResult = (res) => res.data && res.data.authenticate;

export const getRowsResult = (res) => res.data && res.data.rows;

export const getSurveyTimesResult = (res) => res.data && res.data.surveyTimes;

export const getKpisResult = (res) => res.data && res.data.kpis;

export const createQuestionResult = (res) =>
  res.data && res.data.createQuestion;

export const updateQuestionsResult = (res) =>
  res.data && res.data.updateQuestions;

export const deleteCustomerResult = (res) =>
  res.data && res.data.deleteCustomer;

export const createKPIResult = (res) => res.data && res.data.createKPI;

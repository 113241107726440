import { makeStyles, Input as MuiInput } from "@material-ui/core";
import { DIMENS, SPACING } from "../../constants";
import Icon from "../Icon/Icon";

const ITEM_HEIGHT = 40;
const MULTILINE_HEIGHT = 80;

export const Input = ({
  placeholder,
  onChange,
  value,
  icon,
  iconSize = "medium",
  error,
  variant,
  type,
  disabled = false,
  multiline = false,
  onKeyPress,
}) => {
  const classes = useStyles({ icon });
  const getInputStyle = () => {
    let styleName = "";
    switch (variant) {
      default:
        styleName = "primaryInput";
        break;
    }
    return classes[styleName + (error ? "Error" : "")];
  };

  return (
    <MuiInput
      onKeyPress={onKeyPress}
      multiline={multiline}
      rows={3}
      disabled={disabled}
      type={type}
      onChange={(e) => onChange(e.target.value)}
      placeholder={placeholder}
      style={{
        paddingLeft: icon ? 0 : SPACING.medium,
        paddingRight: icon ? 0 : SPACING.medium,
      }}
      inputProps={{
        className: getInputStyle(),
      }}
      className={[
        classes.defaultInput,
        getInputStyle(),
        multiline && classes.multilineInput,
      ].join(" ")}
      disableUnderline
      value={value}
      startAdornment={
        icon && (
          <div className={classes.iconContainer}>
            <Icon name={icon} size={iconSize} />
          </div>
        )
      }
      fullWidth
    />
  );
};

const useStyles = makeStyles((theme) => ({
  defaultInput: {
    height: ITEM_HEIGHT,
    borderRadius: DIMENS.common.borderRadius,
    backgroundColor: theme.palette.white,
    borderColor: theme.palette.grey[30],
    borderWidth: 1,
    borderStyle: "solid",
    width: "100%",
  },
  multilineInput: {
    height: MULTILINE_HEIGHT,
  },
  primaryInput: {
    color: theme.palette.grey[90],
    fontSize: 14,
    fontWeight: 400,
  },
  primaryInputError: {
    borderColor: theme.palette.error.secondary,
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.grey[90],
  },
  iconContainer: {
    paddingLeft: SPACING.small,
    paddingRight: SPACING.small,
  },
}));

export default Input;

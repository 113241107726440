import { makeStyles } from "@material-ui/core";
import React from "react";
import BarLoader from "react-bar-loader";
import theme from "../../theme";

const Loader = () => {
  const classes = useStyles();
  return (
    <BarLoader
      color={theme.palette.secondary.main}
      height="2"
      className={classes.container}
    />
  );
};

const useStyles = makeStyles(() => ({
  container: {
    width: "100%",
    maxWidth: 400,
  },
}));

export default Loader;

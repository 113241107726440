import { makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import { Button, Input, Modal, Spacer, Text } from "../../common";
import { SPACING } from "../../constants";
import { translate } from "../../i18n";

const translateKey = "removeCustomerModal.";

const RemoveCustomerModal = ({ customerName, onRemove, isOpen, onClose }) => {
  const classes = useStyles();

  const [inputName, setInputName] = useState("");

  const conditionForRemovalIsMet = inputName === customerName;

  const handleRemovePressed = () => {
    if (conditionForRemovalIsMet) {
      onRemove();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className={classes.container}>
        <Text variant="h3">
          {translate(translateKey + "header", { customerName })}
        </Text>
        <Spacer spacing="large" />
        <Text variant="caption">
          {translate(translateKey + "subHeader", { customerName })}
        </Text>
        <Spacer spacing="medium" />
        <Input
          placeholder={translate(translateKey + "customerNameInputPlaceholder")}
          value={inputName}
          onChange={setInputName}
        />
        <Spacer spacing="large" />
        <Button
          title={translate(translateKey + "removeButton")}
          icon="remove"
          style={classes.removeButton}
          onClick={handleRemovePressed}
          disabled={!conditionForRemovalIsMet}
        />
      </div>
    </Modal>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: SPACING.medium,
    paddingLeft: 40,
    paddingRight: 40,
    textAlign: "center",
  },
  removeButton: {
    alignSelf: "center",
  },
}));

export default RemoveCustomerModal;

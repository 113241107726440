import { makeStyles } from "@material-ui/core";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getLanguages } from "../../api/graphql/requests";
import { Card, DropdownMultiple } from "../../components";
import { SPACING } from "../../constants";
import { translate } from "../../i18n";
import { copySurveyLink, getTranslationResult, sortBy } from "../../utils";

const SurveysList = ({ surveys, language, type }) => {
  const order = [
    "Baslinje",
    "Startdag",
    "3 månader",
    "6 månader",
    "12 månader",
    "Offboarding",
  ];
  const sortedSurveys = _.sortBy(surveys, (obj) =>
    _.indexOf(order, obj.surveyTime.translation.title)
  );
  const navigate = useNavigate();
  const handleSurveyClick = (surveyId) => {
    navigate(
      `survey/${surveyId}?` + new URLSearchParams({ languageId: language.id })
    );
  };
  const classes = useStyles();
  return (
    <div className={classes.listContainer}>
      {sortedSurveys.map(({ surveyTime, infoTranslations, link, id }) => {
        const title = getTranslationResult(
          surveyTime.translations,
          language.id
        ).title;
        if (
          type.title === "Chef" &&
          (title === "Offboarding" ||
            title === "First day" ||
            title === "Startdag")
        )
          return <></>;
        return (
          <Card
            key={id.toString()}
            onClick={() => handleSurveyClick(id)}
            icon="users"
            title={title}
            description={
              getTranslationResult(infoTranslations, language.id).title
            }
            button={{
              onClick: () => copySurveyLink(link, language.id),
              title: translate("surveysView.copyLink"),
              variant: "secondary",
              icon: "link",
            }}
          />
        );
      })}
    </div>
  );
};

const getCountryPrefix = (languageId) => {
  const translateKey = "surveysView.countryPrefixes.";
  switch (languageId) {
    case 1:
      return translate(translateKey + "se");
    case 2:
      return translate(translateKey + "en");
    default:
      break;
  }
};

const SurveysView = ({ customer }) => {
  const classes = useStyles();
  const [languages, setLanguages] = useState();

  useEffect(() => {
    (async () => {
      setLanguages(await getLanguages());
    })();
  }, []);

  const getDropdownMultipleItems = () => {
    let items = [];
    const employeeGroups = customer.employeeGroups;
    languages.forEach((language) => {
      sortBy(employeeGroups, ["type", "id"]).forEach(({ type, surveys }, i) => {
        items.push({
          title: `${getCountryPrefix(language.id)} ${type.title.toLowerCase()}`,
          icon: language.title,
          Component: () => (
            <SurveysList
              key={`${getCountryPrefix(
                language.id
              )} ${type.title.toLowerCase()} ${i.toString()}`}
              surveys={surveys}
              language={language}
              type={type}
            />
          ),
        });
      });
    });
    return items;
  };

  if (!languages) {
    return <></>;
  }

  return (
    <div className={classes.root}>
      <DropdownMultiple items={getDropdownMultipleItems()} />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  listContainer: {
    display: "grid",
    gap: SPACING.small,
    gridTemplateColumns: "repeat(auto-fit, minmax(290px, 1fr))",
    width: "100%",
  },
  root: {
    width: "100%",
  },
}));

export default SurveysView;

import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { saveImage } from "../../api/functions";
import {
  deleteCustomer,
  getAccentColors,
  getBranches,
  getCustomer,
  getEmployeesCounts,
  updateCustomer,
} from "../../api/graphql/requests";
import { Spacer } from "../../common";
import { Header, LoadingContainer } from "../../components";
import { DEFAULT_LANGUAGE_ID, IMAGE_SIZES, MAX_WIDTH } from "../../constants";
import { translate } from "../../i18n";
import { CustomerDetailsView, SurveysView } from "../../views";
import { toast } from "react-toastify";

const translateKey = "customerScreen.";

const CustomerScreen = () => {
  const classes = useStyles();
  const [customer, setCustomer] = useState();
  const { customerId } = useParams();
  const tabLabels = [translate("tabs.forms"), translate("tabs.details")];
  const [tabValue, setTabValue] = useState(0);
  const [branchesChoices, setBranchesChoices] = useState();
  const [accentColorsChoices, setAccentColorChoices] = useState();
  const [employeesCountsChoices, setEmployeesCountsChoices] = useState();
  const [customerFields, setCustomerFields] = useState();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [logoURL, setLogoURL] = useState();
  const [coverImageURL, setCoverImageURL] = useState();

  useEffect(() => {
    (async () => {
      const customer = await getCustomer(customerId, DEFAULT_LANGUAGE_ID);
      setCustomer(customer);

      //get all dropdownvalues for customerDetailsView
      const branchesChoices = await getBranches();
      setBranchesChoices(branchesChoices);
      const accentColorsChoices = await getAccentColors();
      setAccentColorChoices(accentColorsChoices);
      const employeesCountsChoices = await getEmployeesCounts();
      setEmployeesCountsChoices(employeesCountsChoices);
    })();
  }, [customerId]);

  useEffect(() => {
    if (!customer) {
      return;
    }
    const newCustomerFields = {
      branchDropdownObject: customer.branch,
      accentColor: customer.accentColor,
      employeesCountDropdownObject: customer.employeesCount,
      companyName: customer.companyName,
      logo: customer.logo,
      coverImage: customer.coverImage,
      id: customer.id,
    };
    setCustomerFields(newCustomerFields);
  }, [customer]);

  const handleSaveCoverImage = async () => {
    if (!customer) {
      return;
    }
    const url = await saveImage({
      file: customerFields.coverImage,
      width: IMAGE_SIZES.COVER_IMAGE.width,
      height: IMAGE_SIZES.COVER_IMAGE.height,
    });
    setCoverImageURL(url);
    return url;
  };

  const handleSaveLogo = async (coverImage) => {
    const url = await saveImage({
      file: customerFields.logo,
      width: IMAGE_SIZES.LOGO.width,
      height: IMAGE_SIZES.LOGO.height,
    });
    setLogoURL(url);
    return url;
  };

  const handleSaveImages = async () => {
    setLoading(true);
    const logo =
      customer.logo === customerFields.logo
        ? customer.logo
        : await handleSaveLogo();
    setLogoURL(logo);
    const coverImage =
      customer.coverImage === customerFields.coverImage
        ? customer.coverImage
        : await handleSaveCoverImage();
    setCoverImageURL(coverImage);
    return { coverImage, logo };
  };

  const handleUpdateCustomer = async () => {
    const { logo, coverImage } = await handleSaveImages();
    const customerToPost = {
      branchId: customerFields.branchDropdownObject.id,
      accentColorId: customerFields.accentColor.id,
      coverImage: coverImage,
      logo: logo,
      employeesCountId: customerFields.employeesCountDropdownObject.id,
      companyName: customerFields.companyName,
      id: parseInt(customerId),
    };
    const res = await updateCustomer(customerToPost);
    const updatedCustomer = await getCustomer(customerId, DEFAULT_LANGUAGE_ID);

    if (!res || !updatedCustomer) {
      console.log("something went wrong");
      setLoading(false);
      return;
    }
    setLoading(false);
    setCustomer(updatedCustomer);
    setTabValue(0);
  };

  const handleRemoveCustomer = async () => {
    const res = await deleteCustomer(customer.id);
    if (!res) {
      toast.error(translate(translateKey + "errorMessageRemoval"));
      return;
    }
    toast.success(
      translate(translateKey + "successMessageRemoval", {
        companyName: customer.companyName,
      })
    );
    navigate(-1);
  };

  const someFieldsAreEmpty = () =>
    [undefined, null, [], {}, "", false].some((el) =>
      Object.values(customerFields).includes(el)
    );

  const getView = () => {
    switch (tabValue) {
      case 0:
        return <SurveysView customer={customer} />;
      case 1:
        return (
          <CustomerDetailsView
            header={translate(translateKey + "customerDetailsViewHeader")}
            fields={customerFields}
            onFieldsChange={setCustomerFields}
            branchesChoices={branchesChoices}
            accentColorsChoices={accentColorsChoices}
            employeesCountsChoices={employeesCountsChoices}
            onRemoveCustomer={handleRemoveCustomer}
            customerName={customer.companyName}
            showRemove={true}
          />
        );
      default:
        break;
    }
  };
  if (
    !customer ||
    !branchesChoices ||
    !accentColorsChoices ||
    !employeesCountsChoices ||
    !customerFields
  ) {
    return <div></div>;
  }
  return (
    <div className={classes.root}>
      <Header
        tabLabels={tabLabels}
        tabValue={tabValue}
        setTabValue={setTabValue}
        header={customer.companyName}
        ableToGoBack
        buttons={[
          {
            onClick: handleUpdateCustomer,
            title: translate("customerScreen.saveChangesButton"),
            icon: "file",
            visible: tabValue === 1,
            disabled: someFieldsAreEmpty() || loading,
          },
        ]}
      />
      <Spacer spacing="large" />

      {loading ? (
        <LoadingContainer />
      ) : (
        <div className={classes.viewContainer}>{getView()}</div>
      )}
    </div>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    height: "100%",
  },
  viewContainer: {
    width: "90%",
    maxWidth: MAX_WIDTH,
    display: "flex",
    justifyContent: "center",
  },
}));

export default CustomerScreen;

import { makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import { SPACING } from "../../constants";
import AccentColor from "../AccentColor/AccentColor";

const AccentColorList = ({ colors, onChange, value }) => {
  const [colorMarked, setColorMarked] = useState(value);

  const handleAccentColorOnClick = (color, index) => {
    onChange(color);
    setColorMarked(color);
  };

  const classes = useStyles();
  return (
    <div className={classes.container}>
      {colors.map((color, index) => (
        <AccentColor
          key={color.id}
          color={color.main}
          marked={color.id === colorMarked.id}
          onClick={() => handleAccentColorOnClick(color, index)}
        />
      ))}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    display: "grid",
    justifyItems: "center",
    gap: SPACING.large,
    gridTemplateColumns: "repeat(6, minmax(90px, 1fr))",
    position: "relative",
  },
}));

export default AccentColorList;

import { AwsClient } from "aws4fetch";
import { v4 as uuidv4 } from "uuid";

const s3Bucket = process.env.REACT_APP_S3_BUCKET;
const s3ProjectId = process.env.REACT_APP_S3_PROJECT_ID;
const s3BaseUrl = process.env.REACT_APP_S3_BASE_URL;
const accessToken = process.env.REACT_APP_S3_ACCESS_TOKEN;
const secretToken = process.env.REACT_APP_S3_SECRET_TOKEN;

const aws = new AwsClient({
  accessKeyId: accessToken,
  secretAccessKey: secretToken,
  service: "s3",
});

export const saveImage = async (imageObj) => {
  const file = imageObj.file;
  const res = await aws.fetch(
    `${s3BaseUrl}/${s3ProjectId}:${s3Bucket}/${uuidv4()}-${file.name}`,
    { body: file, method: "PUT" }
  );
  return res.url;
};

import React from "react";
import { Checkbox as MUICheckbox, makeStyles } from "@material-ui/core";
const Checkbox = ({ checked, onClick, className, ...props }) => {
  const classes = useStyles();
  return (
    <MUICheckbox
      checked={checked}
      className={[classes.checkBox, className].join(" ")}
      onClick={onClick}
      {...props}
    />
  );
};

const useStyles = makeStyles((theme) => ({
  checkBox: {
    color: theme.palette.grey[80],
    transform: "scale(0.9)",
    "&.Mui-checked": {
      color: theme.palette.grey[80],
    },
  },
}));

export default Checkbox;

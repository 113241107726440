import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { getSurveyByLink } from "../../api/graphql/requests";
import { IconButton, Spacer, Text } from "../../common";
import { Slogan, SurveyInfoCard } from "../../components";
import { DIMENS, SPACING, URLS } from "../../constants";
import { changeLanguage, translate } from "../../i18n";
import theme from "../../theme";

const MAX_WIDTH = 730;

const SurveySentScreen = () => {
  const translateKey = "surveySentScreen.";
  const classes = useStyles();
  const { link } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [languageId, setLanguageId] = useState();
  const [survey, setSurvey] = useState();

  useEffect(() => {
    setLanguageId(parseInt(searchParams.get("languageId")));
  }, [searchParams]);

  useEffect(() => {
    if (!link || !languageId) {
      return;
    }
    (async () => {
      setSurvey(await getSurveyByLink(link, languageId));
    })();
  }, [link, languageId]);

  useEffect(() => {
    changeLanguage(languageId);
  }, [languageId]);

  if (!survey || !languageId) {
    return;
  }

  return (
    <div
      className={classes.root}
      style={{
        backgroundColor: survey.employeeGroup.customer.accentColor.secondary,
      }}
    >
      <div className={classes.mainContainer}>
        <img
          src={survey.employeeGroup.customer.coverImage}
          className={classes.coverImage}
          alt="coverImage"
        />
        <Spacer spacing={40} />
        <div className={classes.contentContainer}>
          <div className={classes.titleContainer}>
            <IconButton
              iconSize="extraLarge"
              icon="check"
              iconColor={theme.palette.white}
              style={{
                backgroundColor: survey.employeeGroup.customer.accentColor.main,
                boxShadow: "none",
              }}
              variant="circular"
            />
            <Spacer spacing="medium" direction="horizontal" />
            <Text variant="h2" bold>
              {translate(translateKey + "header")}
            </Text>
          </div>
          <Spacer spacing="medium" />
          <Text variant="body">
            {translate(translateKey + "info")}
            <a href={URLS.WHIPPY} className={classes.link}>
              {translate(translateKey + "whippyUrl")}
            </a>
          </Text>
          <Spacer spacing="large" />
          <Text variant="caption" bold>
            {translate(translateKey + "surveyInfoCardHeader")}
          </Text>
          <Spacer spacing="medium" />
          <SurveyInfoCard
            hasShadow={false}
            survey={survey}
            languageId={languageId}
          />
          <Spacer spacing={40} />
          <Slogan />
          <Spacer spacing="large" />
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    height: "100%",
    ...DIMENS.common.centering,
  },
  mainContainer: {
    width: "50%",
    "@media (max-width:1300px)": {
      width: "95%",
    },
    maxWidth: MAX_WIDTH,
    backgroundColor: theme.palette.white,
    borderRadius: DIMENS.common.borderRadius,
  },
  coverImage: {
    width: "100%",
    height: 200,
    borderTopLeftRadius: DIMENS.common.borderRadius,
    borderTopRightRadius: DIMENS.common.borderRadius,
    objectFit: "cover",
    "@media (max-width:900px)": { display: "none" },
  },
  titleContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  link: {
    color: theme.palette.secondary.main,
    fontWeight: 600,
    textDecoration: "none",
  },
  contentContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    paddingLeft: SPACING.large,
    paddingRight: SPACING.large,
  },
}));

export default SurveySentScreen;

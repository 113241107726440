const PasswordSolid = ({ fill, iconSize, style }) => (
  <svg
    width={iconSize}
    height={iconSize}
    viewBox="0 0 14 19"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.0002 6.66683H11.1668V5.00016C11.1668 2.70016 9.30016 0.833496 7.00016 0.833496C4.70016 0.833496 2.8335 2.70016 2.8335 5.00016V6.66683H2.00016C1.0835 6.66683 0.333496 7.41683 0.333496 8.3335V16.6668C0.333496 17.5835 1.0835 18.3335 2.00016 18.3335H12.0002C12.9168 18.3335 13.6668 17.5835 13.6668 16.6668V8.3335C13.6668 7.41683 12.9168 6.66683 12.0002 6.66683ZM7.00016 14.1668C6.0835 14.1668 5.3335 13.4168 5.3335 12.5002C5.3335 11.5835 6.0835 10.8335 7.00016 10.8335C7.91683 10.8335 8.66683 11.5835 8.66683 12.5002C8.66683 13.4168 7.91683 14.1668 7.00016 14.1668ZM4.50016 6.66683V5.00016C4.50016 3.61683 5.61683 2.50016 7.00016 2.50016C8.3835 2.50016 9.50016 3.61683 9.50016 5.00016V6.66683H4.50016Z"
      fill={fill}
    />
  </svg>
);

export default PasswordSolid;

import { useState, useEffect } from "react";
import { getLanguages } from "../api/graphql/requests";

const useLanguages = () => {
  const [languages, setLanguages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchLanguages = async () => {
      setLoading(true);
      setLoading(false);
      try {
        const languagesData = await getLanguages();
        setLanguages(languagesData);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setError(true);
      }
    };

    fetchLanguages();

    return () => {};
  }, []);

  return [languages, loading, error];
};

export default useLanguages;

import { makeStyles } from "@material-ui/core";
import React from "react";
import { Icon, Spacer, Text } from "../../common";
import { DIMENS, SPACING } from "../../constants";
import theme from "../../theme";
import LoadingContainer from "../LoadingContainer/LoadingContainer";

const LIST_ITEM_HEIGHT = 56;

const ListItem = ({ titles, onClick }) => {
  const classes = useStyles();
  return (
    <div className={classes.listItemContainer} onClick={onClick}>
      <div className={classes.titlesContainer}>
        {titles.map((title, index) => (
          <div
            style={{ width: `${100 / titles.length}%` }}
            key={index.toString()}
          >
            <Text variant="body">{title}</Text>
          </div>
        ))}
      </div>
      <Icon name="arrowRight" fill={theme.palette.primary.tintDark} />
    </div>
  );
};

const List = ({ items, loading }) => {
  const classes = useStyles({ titlesCount: items[0].length });
  if (loading) {
    return <LoadingContainer />;
  }
  return (
    <div className={classes.root}>
      {items.map((item, index) =>
        Object.keys(item).includes("titles") ? (
          <div key={index.toString()}>
            {index !== 0 &&
              Object.keys(items[index - 1]).includes("titles") && (
                <Spacer spacing="tiny" />
              )}
            <ListItem titles={item.titles} onClick={item.onClick} />
          </div>
        ) : (
          <div key={index.toString()}>
            <Spacer spacing="small" />
            <Text variant="caption" bold color="grey30">
              {item.header}
            </Text>
            <Spacer spacing="small" />
          </div>
        )
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: { width: "100%" },
  listItemContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    height: LIST_ITEM_HEIGHT,
    paddingRight: SPACING.medium,
    paddingLeft: SPACING.medium,
    alignItems: "center",
    borderRadius: DIMENS.common.borderRadius,
    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.05);",
    ...DIMENS.common.border,
    backgroundColor: theme.palette.white,
    cursor: "pointer",
  },
  titlesContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
}));

export default List;

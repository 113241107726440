import { createTheme } from "@material-ui/core";

const theme = createTheme({
  palette: {
    background: {
      default: "#FFFFFF",
    },
    white: "#ffffff",
    black: "#000",
    transparent: "transparent",
    error: { secondary: "#DD1D1D", third: "#FFF1F0", main: "#A8071A" },
    success: { secondary: "#52C41A", third: "#F6FFED", main: "#237804" },
    primary: {
      main: "#732F3E",
      tintDark: "#B25468",
      shadeDark: "#181547",
    },
    secondary: {
      main: "#FFA8A2",
    },
    grey: {
      90: "#1B1A1F",
      80: "#323138",
      70: "#4D4C54",
      60: "#676570",
      40: "#9A98A3",
      30: "#B2B1BA",
      10: "#E3E1EB",
      2: "#FBFAFF",
      5: "#F1F0F7",
    },
  },
  typography: {
    fontFamily: ["Source Sans Pro"],
    button: { textTransform: "none" },
  },
});

export default theme;

/* eslint-disable no-unused-vars */
import React from "react";
import { Navigate, Route, Routes as DOMRoutes } from "react-router-dom";
import {
  ForgotPasswordScreen,
  LoginScreen,
  SurveyScreen,
  SurveySentScreen,
  UpdatePasswordScreen,
} from "../screens";

const AuthRoutes = () => {
  return (
    <DOMRoutes>
      <Route path="/">
        <Route path="/" element={<Navigate to="login" replace />}></Route>
        <Route path="/login" element={<LoginScreen />} />
        <Route path="/forgot-password" element={<ForgotPasswordScreen />} />
        <Route path="/update-password" element={<UpdatePasswordScreen />} />
        <Route path="/forms">
          <Route path=":link">
            <Route path="" element={<SurveyScreen />} />
            <Route path="sent" element={<SurveySentScreen />} />
          </Route>
        </Route>
        <Route path="/*" element={<Navigate to="login" replace />} />
      </Route>
    </DOMRoutes>
  );
};

export default AuthRoutes;

import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { saveImage } from "../../api/functions";
import {
  createCustomer,
  getAccentColors,
  getBranches,
  getEmployeesCounts,
} from "../../api/graphql/requests";
import { Spacer } from "../../common";
import { Header, LoadingContainer } from "../../components";
import { IMAGE_SIZES, MAX_WIDTH } from "../../constants";
import { translate } from "../../i18n";
import { CustomerDetailsView } from "../../views";

const CreateCustomerScreen = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [branchesChoices, setBranchesChoices] = useState();
  const [accentColorsChoices, setAccentColorChoices] = useState();
  const [employeesCountsChoices, setEmployeesCountsChoices] = useState();
  const [customerFields, setCustomerFields] = useState();
  const [logoURL, setLogoURL] = useState();
  const [coverImageURL, setCoverImageURL] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      const branchesChoices = await getBranches();
      setBranchesChoices(branchesChoices);
      const accentColorsChoices = await getAccentColors();
      setAccentColorChoices(accentColorsChoices);
      const employeesCountsChoices = await getEmployeesCounts();
      setEmployeesCountsChoices(employeesCountsChoices);
    })();
  }, []);

  useEffect(() => {
    let newCustomerFields = {
      branchDropdownObject: undefined,
      accentColor: accentColorsChoices && accentColorsChoices[0],
      employeesCountDropdownObject: undefined,
      logo: undefined,
      coverImage: undefined,
      companyName: undefined,
    };
    setCustomerFields(newCustomerFields);
  }, [accentColorsChoices]);

  const handleSaveCoverImage = async () => {
    const url = await saveImage({
      file: customerFields.coverImage,
      width: IMAGE_SIZES.COVER_IMAGE.width,
      height: IMAGE_SIZES.COVER_IMAGE.height,
    });
    setCoverImageURL(url);
  };

  const handleSaveLogo = async (coverImage) => {
    const url = await saveImage({
      file: customerFields.logo,
      width: IMAGE_SIZES.COVER_IMAGE.width,
      height: IMAGE_SIZES.COVER_IMAGE.height,
    });
    setLogoURL(url);
  };

  useEffect(() => {
    console.log("customerFields", customerFields);
  }, [customerFields]);

  //after images we have fetched imageURLS, we can save the rest of the user
  useEffect(() => {
    if (logoURL && coverImageURL) {
      handleSaveCustomer();
    }
  }, [logoURL, coverImageURL]);

  const handleSaveImages = () => {
    setLoading(true);
    handleSaveCoverImage();
    handleSaveLogo();
  };

  const handleSaveCustomer = async () => {
    const customerToPost = {
      branchId: customerFields.branchDropdownObject.id,
      accentColorId: customerFields.accentColor.id,
      coverImage: coverImageURL,
      logo: logoURL,
      employeesCountId: customerFields.employeesCountDropdownObject.id,
      companyName: customerFields.companyName,
    };
    const res = await createCustomer(customerToPost);
    if (!res) {
      console.log("something went wrong");
      setLoading(false);
      return;
    }
    setLoading(false);
    navigate(`/app/customer/${res.id}`, { replace: true });
  };

  const someFieldsAreEmpty = () =>
    [undefined, null, [], {}, "", false].some((el) =>
      Object.values(customerFields).includes(el)
    );

  if (
    !branchesChoices ||
    !accentColorsChoices ||
    !employeesCountsChoices ||
    !customerFields
  ) {
    return <div />;
  }

  return (
    <div className={classes.root}>
      <Header
        ableToGoBack
        buttons={[
          {
            onClick: handleSaveImages,
            title: translate("createCustomerScreen.saveCustomerButton"),
            disabled: someFieldsAreEmpty() || loading,
            icon: "plusCircle",
          },
        ]}
      />

      <Spacer spacing="large" />
      {loading ? (
        <LoadingContainer />
      ) : (
        <div className={classes.viewContainer}>
          <CustomerDetailsView
            header={translate("createCustomerScreen.customerDetailsViewHeader")}
            branchesChoices={branchesChoices}
            accentColorsChoices={accentColorsChoices}
            employeesCountsChoices={employeesCountsChoices}
            fields={customerFields}
            onFieldsChange={setCustomerFields}
          />
        </div>
      )}
    </div>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  viewContainer: {
    width: "90%",
    maxWidth: MAX_WIDTH,
    display: "flex",
    justifyContent: "center",
  },
}));

export default CreateCustomerScreen;

import { makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Popover } from "../../common";
import { translate } from "../../i18n";
import { useAuth } from "../../providers/AuthProvider";
import ProfileIcon from "../ProfileIcon/ProfileIcon";

const ProfileButton = () => {
  const classes = useStyles();
  const { currentUser, logOut } = useAuth();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState();
  const translateKey = "profile.";
  return (
    <>
      <div
        onClick={(e) => setAnchorEl(e.currentTarget)}
        className={classes.root}
      >
        <ProfileIcon letter={currentUser.email[0]} />
      </div>
      <Popover
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        items={[
          {
            icon: "logOut",
            text: translate(translateKey + "logOut"),
            onClick: logOut,
          },
          {
            icon: "passwordSolid",
            text: translate(translateKey + "changePassword"),
            onClick: () => navigate("/reset-password"),
          },
        ]}
      />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    cursor: "pointer",

    borderRadius: "50%",
  },
}));

export default ProfileButton;

import { makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { DIMENS } from "../../constants";
import theme from "../../theme";
import Icon from "../Icon/Icon";
import IconButton from "../IconButton/IconButton";
import Spacer from "../Spacer/Spacer";
import Text from "../Text/Text";

const ITEM_HEIGHT = 60;

const SNACKBAR_TYPE = {
  SUCCESS: "success",
  ERROR: "error",
};

const Snackbar = ({
  text,
  type = SNACKBAR_TYPE.SUCCESS,
  isVisible,
  setIsVisible,
}) => {
  const classes = useStyles();

  const getContainerStyle = () => {
    switch (type) {
      case SNACKBAR_TYPE.SUCCESS:
        return classes.successContainer;
      case SNACKBAR_TYPE.ERROR:
        return classes.errorContainer;
      default:
        break;
    }
  };

  const getIconName = () => {
    switch (type) {
      case SNACKBAR_TYPE.SUCCESS:
        return "success";
      case SNACKBAR_TYPE.ERROR:
        return "warning";
      default:
        break;
    }
  };
  const getTextColor = () => {
    switch (type) {
      case SNACKBAR_TYPE.SUCCESS:
        return "success";
      case SNACKBAR_TYPE.ERROR:
        return "error";
      default:
        break;
    }
  };

  const getTextIconColor = () => {
    switch (type) {
      case SNACKBAR_TYPE.SUCCESS:
        return theme.palette.success.main;
      case SNACKBAR_TYPE.ERROR:
        return theme.palette.error.main;
      default:
        break;
    }
  };

  useEffect(() => {
    if (isVisible) {
      setTimeout(() => {
        setIsVisible(false);
      }, 3000);
    }
  }, [isVisible]);

  if (!isVisible) {
    return <></>;
  }
  return (
    <div className={[classes.defaultContainer, getContainerStyle()].join(" ")}>
      <div className={classes.leftContainer}>
        <Spacer direction="horizontal" spacing="large" />
        <Icon
          name={getIconName()}
          className={classes.leftIcon}
          fill={getTextIconColor()}
          size="large"
        />
        <Spacer direction="horizontal" spacing="small" />
        <Text variant="body" bold color={getTextColor()}>
          {text}
        </Text>
      </div>
      <div className={classes.rightContainer}>
        <IconButton
          icon="close"
          iconColor={getTextIconColor()}
          onClick={() => setIsVisible(false)}
        />

        <Spacer direction="horizontal" spacing="large" />
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  defaultContainer: {
    width: "100%",
    height: ITEM_HEIGHT,
    borderRadius: DIMENS.common.borderRadius,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    animation: `$fade_in_show 200ms linear`,
  },

  "@keyframes fade_in_show": {
    "0%": {
      opacity: 0,
    },

    "100%": {
      opacity: 1,
    },
  },
  errorContainer: {
    backgroundColor: theme.palette.error.third,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: theme.palette.error.secondary,
  },
  successContainer: {
    backgroundColor: theme.palette.success.third,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: theme.palette.success.secondary,
  },
  leftContainer: {
    display: "flex",
    flexDirection: "row",
  },
  rightContainer: {
    display: "flex",
    flexDirection: "row",
  },
}));

export default Snackbar;

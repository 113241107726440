import i18n from "./i18n";

export const getLanguageTagById = (id) => ({ 1: "sv", 2: "en" }[id]);
export const translate = (key, options) => {
  return key ? i18n.t(key, options) : null;
};

export const changeLanguage = (languageId) => {
  i18n.locale = getLanguageTagById(languageId);
};

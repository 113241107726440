import { makeStyles, Typography } from "@material-ui/core";
import theme from "../../theme";

const getColorByPropsColor = (propsColor) =>
  ({
    grey5: theme.palette.grey[5],
    grey10: theme.palette.grey[10],
    grey30: theme.palette.grey[30],
    grey40: theme.palette.grey[40],
    grey60: theme.palette.grey[60],
    grey70: theme.palette.grey[70],
    grey80: theme.palette.grey[80],
    grey90: theme.palette.grey[90],
    white: theme.palette.white,
    black: theme.palette.black,
    primary: theme.palette.primary.main,
    secondary: theme.palette.secondary.main,
    success: theme.palette.success.main,
    error: theme.palette.error.main,
  }[propsColor]);

const Text = ({
  children,
  style,
  variant,
  color = "grey90",
  bold,
  hidden,
  ...props
}) => {
  const classes = useStyles();

  return (
    <Typography
      className={classes[variant + (bold ? "Semi" : "")]}
      {...props}
      style={{
        color: getColorByPropsColor(color),
        visibility: hidden && "hidden",
        ...style,
      }}
    >
      {children}
    </Typography>
  );
};

const useStyles = makeStyles((theme) => ({
  h2: {
    fontSize: 32,
  },
  h2Semi: {
    fontSize: 32,
    fontWeight: 700,
  },
  h3: {
    fontSize: 20,
  },
  h3Semi: {
    fontSize: 20,
    fontWeight: 600,
  },
  subHeader: {
    fontSize: 18,
  },
  subHeaderSemi: {
    fontSize: 18,
    fontWeight: 600,
  },
  smallText: {
    fontSize: 12,
  },
  smallTextSemi: {
    fontSize: 12,
    fontWeight: 600,
  },
  caption: {
    fontSize: 14,
  },
  captionSemi: {
    fontSize: 14,
    fontWeight: 600,
  },
  body: {
    fontSize: 16,
  },
  bodySemi: {
    fontSize: 16,
    fontWeight: 600,
  },
}));

export default Text;

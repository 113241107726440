import { makeStyles } from "@material-ui/core";
import React from "react";
import { SPACING } from "../../constants";
import Icon from "../Icon/Icon";

const IconButton = ({
  title,
  onClick,
  style,
  variant,
  icon,
  iconSize = "medium",
  iconColor,
  className,
  ...props
}) => {
  const classes = useStyles();

  const getStyle = () => {
    switch (variant) {
      case "circular":
        return classes.circularContainer;
      default:
        break;
    }
  };

  return (
    <div
      onClick={onClick}
      style={style}
      className={[classes.defaultContainer, getStyle(), className].join(" ")}
    >
      <Icon name={icon} size={iconSize} {...props} fill={iconColor} />
    </div>
  );
};
const useStyles = makeStyles((theme) => ({
  defaultContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    padding: SPACING.small,
  },
  circularContainer: {
    borderRadius: 200,
    backgroundColor: theme.palette.white,
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1);",
  },
}));

export default IconButton;

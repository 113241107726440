import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { sendResetPasswordMail } from "../../api/functions";
import { Button, Input, Snackbar, Spacer, Text } from "../../common";
import { Logo } from "../../components";
import { translate } from "../../i18n";

const IMAGE_PATH = "./images/loginScreenImage.png";

const ForgotPasswordScreen = () => {
  const classes = useStyles();
  const translateKey = "forgotPasswordScreen.";
  const [isError, setIsError] = useState();
  const [isSuccess, setIsSuccess] = useState();
  const [email, setEmail] = useState("");
  const [showSnackbar, setShowSnackbar] = useState();

  const handleSendEmail = async () => {
    setIsError(false);
    setIsSuccess(false);
    const res = await sendResetPasswordMail(email);

    if (!res) {
      setIsError(true);
      return;
    }
    setIsSuccess(true);
  };

  useEffect(() => {
    if (isError) {
      setShowSnackbar(true);
    }
  }, [isError]);

  useEffect(() => {
    if (isSuccess) {
      setShowSnackbar(true);
    }
  }, [isSuccess]);

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && !sendButtonIsDisabled) {
      handleSendEmail();
    }
  };

  const sendButtonIsDisabled = email.length === 0;

  return (
    <div className={classes.root}>
      <div className={classes.leftContainer}>
        <div className={classes.contentContainer}>
          <Logo />
          <Spacer spacing="extraLarge" />
          <Text variant="h3" color="primary">
            {translate(translateKey + "subheader")}
          </Text>
          <Spacer spacing="small" />
          <Text variant="body">{translate(translateKey + "description")}</Text>
          <Spacer spacing="large" />

          <Input
            name="email"
            error={isError}
            placeholder={translate(translateKey + "emailPlaceholder")}
            icon="email"
            value={email}
            onChange={setEmail}
            onKeyPress={handleKeyPress}
          />
          <Spacer spacing="large" />
          <div className={classes.buttonContainer}>
            <Button
              disabled={sendButtonIsDisabled}
              title={translate(translateKey + "sendButton")}
              onClick={handleSendEmail}
            />
          </div>

          <Spacer spacing="extraLarge" />
          <Snackbar
            type={isError ? "error" : "success"}
            text={translate(translateKey + (isError ? "error" : "success"))}
            isVisible={showSnackbar}
            setIsVisible={setShowSnackbar}
          />
        </div>
      </div>
      <div className={classes.rightContainer} />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: "100%",
  },
  leftContainer: {
    width: "50%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
  rightContainer: {
    width: "50%",
    height: "100%",
    overflow: "hidden",
    backgroundImage: `url(${IMAGE_PATH})`,
    backgroundSize: "cover",
  },
  contentContainer: {
    width: "70%",
    maxWidth: 600,
  },
  image: {
    height: "100%",
    width: "100%",
    objectFit: "cover",
  },
  buttonContainer: {
    display: "flex",
    alignItems: "flex-end",
    width: "100%",
    flexDirection: "column",
  },
  forgotPasswordLink: {
    textDecoration: "none",
  },
}));

export default ForgotPasswordScreen;

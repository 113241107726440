const Remove = ({ fill, iconSize, style }) => (
  <svg
    width={iconSize}
    height={iconSize}
    viewBox="0 0 12 16"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.00033 13.8333C1.00033 14.75 1.75033 15.5 2.66699 15.5H9.33366C10.2503 15.5 11.0003 14.75 11.0003 13.8333V5.5C11.0003 4.58333 10.2503 3.83333 9.33366 3.83333H2.66699C1.75033 3.83333 1.00033 4.58333 1.00033 5.5V13.8333ZM11.0003 1.33333H8.91699L8.32533 0.741667C8.17533 0.591667 7.95866 0.5 7.74199 0.5H4.25866C4.04199 0.5 3.82533 0.591667 3.67533 0.741667L3.08366 1.33333H1.00033C0.541992 1.33333 0.166992 1.70833 0.166992 2.16667C0.166992 2.625 0.541992 3 1.00033 3H11.0003C11.4587 3 11.8337 2.625 11.8337 2.16667C11.8337 1.70833 11.4587 1.33333 11.0003 1.33333Z"
      fill={fill}
    />
  </svg>
);

export default Remove;

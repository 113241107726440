import { makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import { Button, Dropdown, Input, Modal, Spacer, Text } from "../../common";
import { AccentColorList, RemoveCustomerModal, Upload } from "../../components";
import { translate } from "../../i18n";

const CustomerDetailsView = ({
  branchesChoices,
  accentColorsChoices,
  employeesCountsChoices,
  header,
  fields,
  onFieldsChange,
  customerName,
  onRemoveCustomer,
  showRemove = false,
}) => {
  const classes = useStyles();

  const updateFields = (updatedObject) => {
    onFieldsChange((prev) => ({ ...prev, ...updatedObject }));
  };

  const handleRemoveCustomer = async () => {
    if (await onRemoveCustomer()) {
      setIsRemoveCustomerModalOpen(false);
    }
  };

  const [isRemoveCustomerModalOpen, setIsRemoveCustomerModalOpen] =
    useState(false);

  const translateKey = "customerDetailsView.";
  return (
    <div className={classes.root}>
      <Text variant="h2" bold color="black">
        {header}
      </Text>
      <Spacer spacing="medium" />
      <Text variant="h3" bold color="black">
        {translate(translateKey + "companyDetailsHeader")}
      </Text>
      <Spacer spacing="medium" />
      <Input
        placeholder={translate(translateKey + "placeholderCompanyName")}
        value={fields.companyName}
        onChange={(updated) => updateFields({ companyName: updated })}
      />
      <Spacer spacing="medium" />
      <Dropdown
        placeholder={translate(translateKey + "placeholderBranch")}
        items={branchesChoices}
        value={fields.branchDropdownObject}
        onChange={(updated) => updateFields({ branchDropdownObject: updated })}
      />
      <Spacer spacing="medium" />
      <Dropdown
        placeholder={translate(translateKey + "placeholderEmployeesCount")}
        items={employeesCountsChoices}
        value={fields.employeesCountDropdownObject}
        onChange={(updated) =>
          updateFields({ employeesCountDropdownObject: updated })
        }
      />
      <Spacer spacing="extraLarge" />

      <Text variant="h3" bold color="black">
        {translate(translateKey + "subheader")}
      </Text>
      <Spacer spacing="small" />
      <Text variant="body" color="black">
        {translate(translateKey + "caption")}
      </Text>
      <Spacer spacing="medium" />
      <div className={classes.textHorizontal}>
        <Text variant="body" bold color="black">
          {translate(translateKey + "logoHeader")}
        </Text>
        <Spacer spacing="small" direction="horizontal" />
        <Text variant="body" color="black">
          {translate(translateKey + "logoFormat")}
        </Text>
      </div>
      <Spacer spacing="tiny" />
      <Text variant="body" color="black">
        {translate(translateKey + "logoInfo")}
      </Text>
      <Spacer spacing="large" />

      <Upload
        onImageChange={(updated) => updateFields({ logo: updated })}
        image={fields.logo}
        variant="square"
      />

      <Spacer spacing="medium" />
      <div className={classes.textHorizontal}>
        <Text variant="body" bold color="black">
          {translate(translateKey + "coverImageHeader")}
        </Text>
        <Spacer spacing="small" direction="horizontal" />
        <Text variant="body" color="black">
          {translate(translateKey + "coverImageFormat")}
        </Text>
      </div>
      <Spacer spacing="tiny" />
      <Text variant="body" color="black">
        {translate(translateKey + "coverImageInfo")}
      </Text>
      <Spacer spacing="large" />
      <Upload
        onImageChange={(updated) => updateFields({ coverImage: updated })}
        image={fields.coverImage}
      />
      <Spacer spacing="medium" />
      <Text variant="body" bold color="black">
        {translate(translateKey + "accentColorHeader")}
      </Text>
      <Spacer spacing="tiny" />
      <Text
        variant="body"
        color="black
      
      "
      >
        {translate(translateKey + "accentColorInfo")}
      </Text>
      <Spacer spacing="extraLarge" />

      <AccentColorList
        colors={accentColorsChoices}
        value={fields.accentColor}
        onChange={(updated) => updateFields({ accentColor: updated })}
      />
      {showRemove && (
        <>
          <Spacer spacing={200} />

          <div className={classes.removeButtonContainer}>
            <Button
              onClick={() => setIsRemoveCustomerModalOpen(true)}
              title={translate("customerScreen.removeCustomerButton")}
              icon={"remove"}
            />
            <RemoveCustomerModal
              isOpen={isRemoveCustomerModalOpen}
              onClose={() => setIsRemoveCustomerModalOpen(false)}
              customerName={customerName}
              onRemove={handleRemoveCustomer}
            />
          </div>
        </>
      )}
      <Spacer />
    </div>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    width: "70%",
  },
  textHorizontal: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  removeButtonContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default CustomerDetailsView;

import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { RadioButton, Spacer, Text } from "../../common";
import { useWindowSize } from "../../utils";

const LIST_DIRECTION = {
  VERTICAL: "vertical",
  HORIZONTAL: "horizontal",
};

const RESIZE_WIDTH = 600;

const RadioButtonList = ({
  value,
  onChange,
  items,
  disabled,
  direction = "vertical",
  leftText,
  rightText,
  checkedColor,
}) => {
  const classes = useStyles();
  const [windowWidth, setWindowWidth] = useWindowSize();
  const [directionState, setDirectionState] = useState();

  useEffect(() => {
    if (windowWidth < RESIZE_WIDTH) {
      setDirectionState(LIST_DIRECTION.VERTICAL);
      return;
    }
    setDirectionState(direction);
  }, [windowWidth]);

  useEffect(() => {
    setDirectionState(direction);
  }, []);

  const handleRadioButtonClick = (item) => {
    if (disabled) {
      return;
    }
    onChange(item);
  };

  const getRootStyle = () => {
    switch (directionState) {
      case LIST_DIRECTION.HORIZONTAL:
        return classes.rootHorizontal;
      case LIST_DIRECTION.VERTICAL:
        return classes.rootVertical;
      default:
        break;
    }
  };

  return (
    <div className={getRootStyle()}>
      <div className={classes.innerContainer}>
        {directionState === LIST_DIRECTION.HORIZONTAL && (
          <>
            <div className={getRootStyle()}>
              <Text hidden>{leftText}</Text>
              {items.map((item, index) => (
                <div
                  className={classes.itemContainerHorizontal}
                  key={item.id.toString()}
                >
                  <Text>{item.title}</Text>
                </div>
              ))}
              <Text hidden>{rightText}</Text>
            </div>
            <Spacer spacing="medium" />
          </>
        )}

        <div className={getRootStyle()}>
          <Text>{leftText}</Text>
          {leftText && directionState === LIST_DIRECTION.VERTICAL && (
            <Spacer spacing="medium" />
          )}

          {items.map((item, index) => (
            <div key={item.id.toString()} className={classes.itemContainer}>
              {directionState === LIST_DIRECTION.VERTICAL ? (
                <>
                  {index !== 0 && <Spacer spacing="medium" />}
                  <div className={classes.itemContainerVertical}>
                    <RadioButton
                      checkedColor={checkedColor}
                      disabled={disabled}
                      checked={!disabled && value?.id === item.id}
                      onClick={() => handleRadioButtonClick(item)}
                    />
                    <Spacer spacing={12} direction="horizontal" />
                    <Text variant="body">{item.title}</Text>
                  </div>
                </>
              ) : (
                <>
                  <div className={classes.itemContainerHorizontal}>
                    <RadioButton
                      checkedColor={checkedColor}
                      disabled={disabled}
                      checked={!disabled && value?.id === item.id}
                      onClick={() => handleRadioButtonClick(item)}
                    />
                  </div>
                </>
              )}
            </div>
          ))}
          {rightText && directionState === LIST_DIRECTION.VERTICAL && (
            <Spacer spacing="medium" />
          )}
          <Text>{rightText}</Text>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  rootVertical: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  rootHorizontal: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: "100%",
  },
  itemContainerVertical: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  itemContainerHorizontal: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
  },
  innerContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  itemContainer: {
    width: "100%",
  },
}));

export default RadioButtonList;

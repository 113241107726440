import { makeStyles } from "@material-ui/core";
import React from "react";
import { Icon } from "../../common";
import { DIMENS, SPACING } from "../../constants";
import theme from "../../theme";

const CONTAINER_SIZE = {
  LARGE: 82,
  MEDIUM: 64,
};

const AccentColor = ({ onClick, marked, color, icon = "check" }) => {
  const classes = useStyles();

  return (
    <div
      className={[classes.container, classes.mediumContainer].join(" ")}
      style={{ backgroundColor: color }}
      onClick={onClick}
    >
      {marked && (
        <div
          className={[classes.container, classes.largeContainer].join(" ")}
          style={{ backgroundColor: color }}
        >
          <Icon name={icon} size="large" fill={theme.palette.white} />
        </div>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.primary.main,
    padding: SPACING.small,
    borderRadius: "50%",
    cursor: "pointer",
    ...DIMENS.common.centering,
    position: "relative",
  },
  mediumContainer: {
    width: CONTAINER_SIZE.MEDIUM,
    height: CONTAINER_SIZE.MEDIUM,
  },
  largeContainer: {
    width: CONTAINER_SIZE.LARGE,
    height: CONTAINER_SIZE.LARGE,
    position: "absolute",
    top: (CONTAINER_SIZE.MEDIUM - CONTAINER_SIZE.LARGE) / 2,
  },
}));

export default AccentColor;

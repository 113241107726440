import {
  CircularProgress,
  Button as MUIButton,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import { DIMENS } from "../../constants";
import theme from "../../theme";
import Icon from "../Icon/Icon";
import Text from "../Text/Text";

const ITEM_HEIGHT = 40;

const Button = ({
  title,
  onClick,
  style,
  disabled = false,
  variant,
  icon,
  iconSize = "large",
  color,
  loading,
  ...props
}) => {
  const classes = useStyles();
  const getButtonStyle = () => {
    let styleName = "";

    switch (variant) {
      case "secondary":
        styleName = "secondaryButton";
        break;
      case "third":
        styleName = "thirdButton";
        break;
      default:
        styleName = "primaryButton";
        break;
    }
    return classes[styleName + (disabled ? "Disabled" : "")];
  };
  const getTextColor = () => {
    switch (variant) {
      case "secondary":
        return disabled ? "grey90" : "primary";
      case "third":
        return disabled ? "grey90" : "primary";
      default:
        return "white";
    }
  };

  const getIconColor = () => {
    switch (variant) {
      case "secondary":
        return theme.palette.primary.main;
      case "third":
        return theme.palette.primary.main;
      default:
        return theme.palette.white;
    }
  };

  return (
    <div className={classes.root}>
      <MUIButton
        className={[classes.defaultStyle, getButtonStyle()].join(" ")}
        disabled={disabled}
        onClick={onClick}
        style={{
          backgroundColor: !disabled && color,
        }}
        startIcon={
          icon && <Icon name={icon} size={iconSize} fill={getIconColor()} />
        }
        {...props}
      >
        {loading ? (
          <CircularProgress size={24} style={{ color: theme.palette.white }} />
        ) : (
          <Text variant={"body"} color={getTextColor()}>
            {title}
          </Text>
        )}
      </MUIButton>
    </div>
  );
};
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.white,
    borderRadius: DIMENS.common.borderRadius,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  defaultStyle: {
    borderRadius: DIMENS.common.borderRadius,
    ...DIMENS.common.centering,
    paddingLeft: 20,
    paddingRight: 20,
    height: ITEM_HEIGHT,
    transition: "0.3s",
  },
  primaryButton: {
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.tintDark,
    },
  },
  primaryButtonDisabled: {
    backgroundColor: theme.palette.primary.shadeDark,
    opacity: 0.3,
  },
  secondaryButton: {
    borderStyle: "solid",
    borderColor: theme.palette.primary.main,
    borderWidth: 1,
    borderRadius: DIMENS.common.borderRadius,
    backgroundColor: theme.palette.white,
    "&:hover": {
      backgroundColor: theme.palette.grey[5],
    },
  },
  secondaryButtonDisabled: {
    borderStyle: "solid",
    borderColor: theme.palette.grey[30],
    borderWidth: 1,
    borderRadius: DIMENS.common.borderRadius,
    backgroundColor: theme.palette.white,
    opacity: 0.3,
  },
  thirdButton: {
    borderRadius: DIMENS.common.borderRadius,
    backgroundColor: theme.palette.white,
    "&:hover": {
      backgroundColor: theme.palette.grey[5],
    },
  },
  thirdButtonDisabled: {
    borderRadius: DIMENS.common.borderRadius,
    backgroundColor: theme.palette.white,
    opacity: 0.3,
  },
}));

export default Button;

const Link = ({ fill, iconSize, style }) => (
  <svg
    width={iconSize}
    height={iconSize}
    viewBox="0 0 21 10"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.95995 4.38C2.23995 2.91 3.61995 1.9 5.11995 1.9H8.04995C8.56995 1.9 8.99995 1.47 8.99995 0.95C8.99995 0.43 8.56995 0 8.04995 0H5.21995C2.60995 0 0.279953 1.91 0.0299532 4.51C-0.260047 7.49 2.07995 10 4.99995 10H8.04995C8.56995 10 8.99995 9.57 8.99995 9.05C8.99995 8.53 8.56995 8.1 8.04995 8.1H4.99995C3.08995 8.1 1.57995 6.36 1.95995 4.38ZM6.99995 6H13C13.55 6 14 5.55 14 5C14 4.45 13.55 4 13 4H6.99995C6.44995 4 5.99995 4.45 5.99995 5C5.99995 5.55 6.44995 6 6.99995 6ZM14.78 0H11.95C11.43 0 11 0.43 11 0.95C11 1.47 11.43 1.9 11.95 1.9H14.88C16.38 1.9 17.76 2.91 18.04 4.38C18.42 6.36 16.91 8.1 15 8.1H11.95C11.43 8.1 11 8.53 11 9.05C11 9.57 11.43 10 11.95 10H15C17.92 10 20.26 7.49 19.98 4.51C19.73 1.91 17.39 0 14.78 0Z"
      fill={fill}
    />
  </svg>
);

export default Link;

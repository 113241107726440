import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import {
  getCustomers,
  getKpis,
  getRows,
  getSurveyTimes,
} from "../../api/graphql/requests";

import { Spacer } from "../../common";
import { Header } from "../../components";
import { DEFAULT_LANGUAGE_ID, MAX_WIDTH } from "../../constants";
import { translate } from "../../i18n";
import { exportToCsv, getFilename, isMatrixEven } from "../../utils";

import { CustomersView, SheetView } from "../../views";
import { toast } from "react-toastify";

const MainScreen = () => {
  const classes = useStyles();
  const tabLabels = [translate("tabs.start"), translate("tabs.customers")];
  const [tabValue, setTabValue] = useState(0);

  const [customerValues, setCustomerValues] = useState([]);
  const [customerDropdownItems, setCustomerDropdownItems] = useState();
  const [surveyTimeValues, setSurveyTimeValues] = useState([]);
  const [surveyTimeDropdownItems, setSurveyTimeDropdownItems] = useState();
  const [kpiValues, setKpiValues] = useState([]);
  const [kpiDropdownItems, setKpiDropdownItems] = useState();
  const [loadingSheet, setLoadingSheet] = useState(false);
  const [loadingCustomers, setLoadingCustomers] = useState(false);
  const [customers, setCustomers] = useState();
  const [rows, setRows] = useState();

  useEffect(() => {
    (async () => {
      setLoadingCustomers(true);
      setCustomers(await getCustomers());
      setLoadingCustomers(false);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (!customers) {
        return;
      }
      setCustomerDropdownItems(
        customers.map(({ id, companyName: title }) => ({
          id,
          title,
        }))
      );
    })();
  }, [customers]);

  useEffect(() => {
    (async () => {
      setSurveyTimeDropdownItems(
        (await getSurveyTimes(DEFAULT_LANGUAGE_ID)).map(
          ({ id, translation }) => ({
            id,
            title: translation.title,
          })
        )
      );
    })();
  }, []);

  useEffect(() => {
    (async () => {
      setKpiDropdownItems(
        (await getKpis(DEFAULT_LANGUAGE_ID)).map(({ id, translation }) => ({
          id,
          title: translation.title,
        }))
      );
    })();
  }, []);

  const handleResetFilters = () => {
    setKpiValues([]);
    setCustomerValues([]);
    setSurveyTimeValues([]);
  };

  const handleExport = (rows) => {
    const rowsWithOnlyText = rows.map((innerList) =>
      innerList.map(({ title }) => title || "")
    );
    const csvFilename = getFilename(
      customerValues.length === customerDropdownItems.length
        ? `all-customers-data`
        : `${customerValues.map((v) => v.title).join("-")}-data`
    );
    exportToCsv(csvFilename, rowsWithOnlyText);
  };

  const isSheetValid = (rows) =>
    isMatrixEven(rows) && rows.every((row) => !row.includes(null));

  const onSearch = async () => {
    if (
      customerValues.length === 0 ||
      kpiValues.length === 0 ||
      surveyTimeValues.length === 0
    ) {
      return;
    }
    setLoadingSheet(true);
    const { rows } = await getRows(
      customerValues.map(({ id }) => id),
      kpiValues.map(({ id }) => id),
      surveyTimeValues.map(({ id }) => id),
      DEFAULT_LANGUAGE_ID
    );

    if (!rows || !isSheetValid(rows)) {
      toast.error(translate("mainScreen.errorSheetRetrieval"));
      setLoadingSheet(false);
      return;
    }
    setRows(rows);

    setLoadingSheet(false);
  };

  const getView = () => {
    switch (tabValue) {
      case 0:
        return (
          <SheetView
            customerDropdownItems={customerDropdownItems}
            surveyTimeDropdownItems={surveyTimeDropdownItems}
            kpiDropdownItems={kpiDropdownItems}
            onCustomerValuesChange={setCustomerValues}
            customerValues={customerValues}
            onKpiValuesChange={setKpiValues}
            kpiValues={kpiValues}
            onSurveyTimeValuesChange={setSurveyTimeValues}
            surveyTimeValues={surveyTimeValues}
            loading={loadingSheet}
            onResetFilters={handleResetFilters}
            onExport={handleExport}
            rows={rows}
            onSearch={onSearch}
          />
        );
      case 1:
        return (
          <CustomersView customers={customers} loading={loadingCustomers} />
        );
      default:
        break;
    }
  };

  return (
    <div className={classes.root}>
      <Header
        tabLabels={tabLabels}
        tabValue={tabValue}
        setTabValue={setTabValue}
        showLogo
        showProfileButton
      />
      <Spacer spacing="large" />
      <div className={classes.viewContainer}>{getView()}</div>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    height: "100%",
  },
  viewContainer: {
    width: "90%",
    maxWidth: MAX_WIDTH,
    display: "flex",
    justifyContent: "center",
    height: "100%",
  },
}));

export default MainScreen;

import { toast } from "react-toastify";
import { client } from "./client";
import {
  authenticateMutation,
  createAnswersMutation,
  createCustomerMutation,
  deleteCustomerMutation,
  createQuestionMutation,
  signInMutation,
  signUpMutation,
  updateCustomerMutation,
  updateQuestionsMutation,
  updateQuestionTranslationsMutation,
  updateSurveyInfoTranslationMutation,
  createKPIMutation,
} from "./mutations";
import {
  accentColorsQuery,
  branchesQuery,
  customerQuery,
  customersQuery,
  employeesCountsQuery,
  kpisQuery,
  languagesQuery,
  rowsQuery,
  surveyByLinkQuery,
  surveyQuery,
  surveyTimesQuery,
} from "./queries";
import {
  authenticateResult,
  createAnswersResult,
  createCustomerResult,
  deleteCustomerResult,
  createQuestionResult,
  getAccentColorsResult,
  getBranchesResult,
  getCustomerResult,
  getCustomersResult,
  getEmployeesCountsResult,
  getKpisResult,
  getLanguagesResult,
  getRowsResult,
  getSurveyByLinkResult,
  getSurveyResult,
  getSurveyTimesResult,
  signInResult,
  signUpResult,
  updateCustomerResult,
  updateQuestionsResult,
  updateQuestionTranslationsResult,
  updateSurveyInfoTranslationResult,
  createKPIResult,
} from "./responeParsers";

export const getCustomer = async (id, languageId) => {
  try {
    const res = await client.query({
      query: customerQuery,
      variables: { id, languageId },
    });

    return getCustomerResult(res);
  } catch (e) {
    console.log(e);
  }
};

export const createCustomer = async (obj) => {
  try {
    const res = await client.mutate({ mutation: createCustomerMutation(obj) });
    return createCustomerResult(res);
  } catch (e) {
    console.log(e);
  }
};

export const createQuestion = async (obj) => {
  try {
    const res = await client.mutate({ mutation: createQuestionMutation(obj) });
    return createQuestionResult(res);
  } catch (e) {
    console.log(e);
  }
};

export const updateCustomer = async (obj) => {
  try {
    const res = await client.mutate({ mutation: updateCustomerMutation(obj) });

    return updateCustomerResult(res);
  } catch (e) {
    console.log(e);
  }
};

export const deleteCustomer = async (id) => {
  try {
    const res = await client.mutate({ mutation: deleteCustomerMutation(id) });
    return deleteCustomerResult(res);
  } catch (e) {
    console.log(e);
  }
};

export const getLanguages = async () => {
  try {
    const res = await client.query({ query: languagesQuery });
    return getLanguagesResult(res);
  } catch (e) {
    console.log(e);
  }
};

export const getBranches = async (obj) => {
  try {
    const res = await client.query({ query: branchesQuery });
    return getBranchesResult(res);
  } catch (e) {
    console.log(e);
  }
};

export const getAccentColors = async (obj) => {
  try {
    const res = await client.query({ query: accentColorsQuery });
    return getAccentColorsResult(res);
  } catch (e) {
    console.log(e);
  }
};

export const getCustomers = async (languageId) => {
  try {
    const res = await client.query({
      query: customersQuery,
      variables: { languageId },
    });
    return getCustomersResult(res);
  } catch (e) {
    console.log(e);
  }
};

export const getEmployeesCounts = async () => {
  try {
    const res = await client.query({ query: employeesCountsQuery });
    return getEmployeesCountsResult(res);
  } catch (e) {
    console.log(e);
  }
};

export const getSurvey = async (id, languageId) => {
  try {
    const res = await client.query({
      query: surveyQuery,
      variables: { id, languageId },
    });
    return getSurveyResult(res);
  } catch (e) {
    console.log(e);
  }
};

export const updateSurveyInfoTranslation = async (obj) => {
  try {
    const res = await client.mutate({
      mutation: updateSurveyInfoTranslationMutation(obj),
    });

    return updateSurveyInfoTranslationResult(res);
  } catch (e) {
    console.log(e);
  }
};

export const updateQuestionTranslations = async (lst) => {
  try {
    const res = await client.mutate({
      mutation: updateQuestionTranslationsMutation(lst),
    });
    return updateQuestionTranslationsResult(res);
  } catch (e) {
    console.log(e);
  }
};

export const updateQuestions = async (questions) => {
  try {
    const res = await client.mutate({
      mutation: updateQuestionsMutation(questions),
    });
    return updateQuestionsResult(res);
  } catch (e) {
    console.log(e);
  }
};

export const getSurveyByLink = async (link, languageId) => {
  try {
    const res = await client.query({
      query: surveyByLinkQuery,
      variables: { link, languageId },
    });

    return getSurveyByLinkResult(res);
  } catch (e) {
    console.log(e);
  }
};

export const createAnswers = async (answers, surveyId) => {
  try {
    const res = await client.mutate({
      mutation: createAnswersMutation,
      variables: { answers, surveyId },
    });

    return createAnswersResult(res);
  } catch (e) {
    toast.error(e.message.replace("GraphQL error: ", ""));
  }
};
export const createKPI = async (kpi) => {
  try {
    const res = await client.mutate({
      mutation: createKPIMutation,
      variables: { kpi },
    });

    return createKPIResult(res);
  } catch (e) {
    console.log(e);
  }
};

export const signUp = async (email, password) => {
  try {
    const res = await client.mutate({
      mutation: signUpMutation,
      variables: { email, password },
    });

    return signUpResult(res);
  } catch (e) {
    console.log(e);
  }
};

export const signIn = async (email, password) => {
  try {
    const res = await client.mutate({
      mutation: signInMutation,
      variables: { email, password },
    });

    return signInResult(res);
  } catch (e) {
    console.log(e);
  }
};

export const authenticate = async () => {
  try {
    const res = await client.mutate({
      mutation: authenticateMutation,
    });

    return authenticateResult(res);
  } catch (e) {
    console.log(e);
  }
};

export const getRows = async (
  customerIds,
  kpiIds,
  surveyTimeIds,
  languageId
) => {
  try {
    console.log(customerIds, kpiIds, surveyTimeIds, languageId);
    const res = await client.query({
      query: rowsQuery,
      variables: { customerIds, kpiIds, surveyTimeIds, languageId },
    });
    return getRowsResult(res);
  } catch (e) {
    console.log(e);
  }
};

export const getSurveyTimes = async (languageId) => {
  try {
    const res = await client.query({
      query: surveyTimesQuery,
      variables: { languageId },
    });
    return getSurveyTimesResult(res);
  } catch (e) {
    console.log(e);
  }
};

export const getKpis = async (languageId) => {
  try {
    const res = await client.query({
      query: kpisQuery,
      variables: { languageId },
    });
    return getKpisResult(res);
  } catch (e) {
    console.log(e);
  }
};

import React from "react";
import { makeStyles, Popover as MUIPopover } from "@material-ui/core";
import Icon from "../Icon/Icon";
import Text from "../Text/Text";
import { DIMENS, SPACING } from "../../constants";
import Spacer from "../Spacer/Spacer";
import ReactModal from "react-modal";
const ITEM_WIDTH = 200;

const Modal = ({ isOpen, children, onClose, ...props }) => {
  const classes = useStyles();
  return (
    <ReactModal isOpen={isOpen} className={classes.root} {...props}>
      <div className={classes.modalWrapper} onClick={onClose}>
        <div className={classes.modalContainer}>
          <div onClick={(e) => e.stopPropagation()}>{children}</div>
        </div>
      </div>
    </ReactModal>
  );
};

const useStyles = makeStyles((theme) => ({
  root: { width: "100%", height: "100%" },
  modalWrapper: {
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  modalContainer: {
    borderRadius: DIMENS.common.borderRadius,
    borderWidth: DIMENS.common.border.borderWidth,
    borderStyle: DIMENS.common.border.borderStyle,
    borderColor: DIMENS.common.border.borderColor,
    backgroundColor: theme.palette.white,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default Modal;

import { useLayoutEffect, useState } from "react";

import _ from "underscore";
import { URLS } from "../constants";

export const sortBy = (array, keys) => {
  const getValueToCompare = (val) => {
    let tempVal = val;
    keys.forEach((key) => (tempVal = tempVal[key]));
    return tempVal;
  };
  return array.sort((a, b) => getValueToCompare(a) - getValueToCompare(b));
};

export const copySurveyLink = (link, languageId) => {
  navigator.clipboard.writeText(
    `${URLS.FRONTEND}/forms/${link}?` + new URLSearchParams({ languageId })
  );
};

export const getTranslationResult = (translation, languageId) =>
  translation.filter(({ language }) => language.id === languageId)[0];

export const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
};

export const exportToCsv = (filename, rows) => {
  const processRow = (row) => {
    var finalVal = "";
    for (var j = 0; j < row.length; j++) {
      var innerValue = row[j] === null ? "" : row[j].toString();
      if (row[j] instanceof Date) {
        innerValue = row[j].toLocaleString();
      }
      var result = innerValue.replace(/"/g, '""');
      if (result.search(/("|,|\n)/g) >= 0) result = '"' + result + '"';
      if (j > 0) finalVal += ",";
      finalVal += result;
    }
    return finalVal + "\n";
  };

  var csvFile = "";
  for (var i = 0; i < rows.length; i++) {
    csvFile += processRow(rows[i]);
  }

  var blob = new Blob([csvFile], { type: "text/csv;charset=utf-8;" });
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, filename);
  } else {
    var link = document.createElement("a");
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      var url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};

export const getFilename = (text) => text.replaceAll(" ", "-").toLowerCase();

export const getRoundedNumber = (n, d) => {
  var m = Math.pow(10, d);
  return Math.round(n * m) / m;
};

export const getResizedImage = ({ file, width, height }) => {
  var reader = new FileReader();
  var image = new Image();
  var canvas = document.createElement("canvas");
  var dataURItoBlob = function (dataURI) {
    var bytes =
      dataURI.split(",")[0].indexOf("base64") >= 0
        ? atob(dataURI.split(",")[1])
        : unescape(dataURI.split(",")[1]);
    var mime = dataURI.split(",")[0].split(":")[1].split(";")[0];
    var max = bytes.length;
    var ia = new Uint8Array(max);
    for (var i = 0; i < max; i++) ia[i] = bytes.charCodeAt(i);
    return new Blob([ia], { type: mime });
  };
  const resize = () => {
    canvas.width = width;
    canvas.height = height;
    const iw = image.width;
    const ih = image.height;
    const cw = canvas.width;
    const ch = canvas.height;
    const f = Math.max(cw / iw, ch / ih);
    const ctx = canvas.getContext("2d");
    ctx.setTransform(f, 0, 0, f, (cw - f * iw) / 2, (ch - f * ih) / 2);
    ctx.drawImage(image, 0, 0);
    var dataUrl = canvas.toDataURL("image/jpeg");
    return dataURItoBlob(dataUrl);
  };
  return new Promise((ok, no) => {
    if (!file.type.match(/image.*/)) {
      no(new Error("Not an image"));
      return;
    }
    reader.onload = function (readerEvent) {
      image.onload = function () {
        return ok(resize());
      };
      image.src = readerEvent.target.result;
    };
    reader.readAsDataURL(file);
  });
};

export const isMatrixEven = (matrix) => {
  return (
    Array.isArray(matrix) &&
    matrix.length > 0 &&
    matrix.every((row) => Array.isArray(row) && row.length === matrix[0].length)
  );
};

/*const fit = (contains) => {
  return (
    parentWidth,
    parentHeight,
    childWidth,
    childHeight,
    scale = 1,
    offsetX = 0.5,
    offsetY = 0.5
  ) => {
    const childRatio = childWidth / childHeight;
    const parentRatio = parentWidth / parentHeight;
    let width = parentWidth * scale;
    let height = parentHeight * scale;

    if (contains ? childRatio > parentRatio : childRatio < parentRatio) {
      height = width / childRatio;
    } else {
      width = height * childRatio;
    }

    return {
      width,
      height,
      offsetX: (parentWidth - width) * offsetX,
      offsetY: (parentHeight - height) * offsetY,
    };
  };
};

export const contain = fit(true);
export const cover = fit(false);*/

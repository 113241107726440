const EN = ({ fill, iconSize, style }) => (
  <svg
    width={iconSize}
    height={iconSize}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
      fill="#F0F0F0"
    />
    <path
      d="M1.65355 3.12939C1.02514 3.94699 0.551297 4.88933 0.275391 5.91305H4.4372L1.65355 3.12939Z"
      fill="#0052B4"
    />
    <path
      d="M15.7242 5.91302C15.4483 4.88933 14.9745 3.94699 14.3461 3.12939L11.5625 5.91302H15.7242Z"
      fill="#0052B4"
    />
    <path
      d="M0.275391 10.0872C0.551328 11.1108 1.02517 12.0532 1.65355 12.8708L4.43711 10.0872H0.275391Z"
      fill="#0052B4"
    />
    <path
      d="M12.8705 1.65382C12.0529 1.02542 11.1106 0.551572 10.0869 0.275635V4.43742L12.8705 1.65382Z"
      fill="#0052B4"
    />
    <path
      d="M3.12891 14.3463C3.9465 14.9747 4.88884 15.4486 5.91253 15.7245V11.5627L3.12891 14.3463Z"
      fill="#0052B4"
    />
    <path
      d="M5.9125 0.275635C4.88881 0.551572 3.94647 1.02542 3.12891 1.65379L5.9125 4.43738V0.275635Z"
      fill="#0052B4"
    />
    <path
      d="M10.0869 15.7245C11.1106 15.4486 12.0529 14.9747 12.8705 14.3463L10.0869 11.5627V15.7245Z"
      fill="#0052B4"
    />
    <path
      d="M11.5625 10.0872L14.3461 12.8708C14.9745 12.0532 15.4483 11.1108 15.7242 10.0872H11.5625Z"
      fill="#0052B4"
    />
    <path
      d="M15.9323 6.95653H9.04353H9.0435V0.0677188C8.70191 0.02325 8.35366 0 8 0C7.64628 0 7.29809 0.02325 6.95653 0.0677188V6.95647V6.9565H0.0677188C0.02325 7.29809 0 7.64634 0 8C0 8.35372 0.02325 8.70191 0.0677188 9.04347H6.95647H6.9565V15.9323C7.29809 15.9768 7.64628 16 8 16C8.35366 16 8.70191 15.9768 9.04347 15.9323V9.04353V9.0435H15.9323C15.9768 8.70191 16 8.35372 16 8C16 7.64634 15.9768 7.29809 15.9323 6.95653Z"
      fill="#D80027"
    />
    <path
      d="M10.0869 10.0869L13.6568 13.6568C13.821 13.4927 13.9776 13.3211 14.127 13.1432L11.0707 10.0869H10.0869V10.0869Z"
      fill="#D80027"
    />
    <path
      d="M5.91268 10.0869H5.91262L2.34277 13.6568C2.5069 13.8209 2.67849 13.9776 2.85637 14.127L5.91268 11.0706V10.0869Z"
      fill="#D80027"
    />
    <path
      d="M5.91317 5.91299V5.91292L2.3433 2.34302C2.17911 2.50714 2.02248 2.67874 1.87305 2.85661L4.92939 5.91296H5.91317V5.91299Z"
      fill="#D80027"
    />
    <path
      d="M10.0869 5.9132L13.6568 2.34327C13.4927 2.17908 13.3211 2.02245 13.1432 1.87305L10.0869 4.92939V5.9132Z"
      fill="#D80027"
    />
  </svg>
);

export default EN;

const arrowRight = ({ fill, iconSize, style }) => (
  <svg
    width={iconSize}
    height={iconSize}
    viewBox="0 0 10 16"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.15007 15.5084C1.55841 15.9167 2.21674 15.9167 2.62507 15.5084L9.55007 8.58337C9.87507 8.25837 9.87507 7.73337 9.55007 7.40837L2.62507 0.483374C2.21674 0.0750407 1.55841 0.0750407 1.15007 0.483374C0.74174 0.891707 0.74174 1.55004 1.15007 1.95837L7.18341 8.00004L1.14174 14.0417C0.74174 14.4417 0.74174 15.1084 1.15007 15.5084Z"
      fill={fill}
    />
  </svg>
);

export default arrowRight;

const Dots = ({ fill, iconSize, style }) => (
  <svg
    width={iconSize}
    height={iconSize}
    viewBox="0 0 4 14"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.99967 3.66659C2.91634 3.66659 3.66634 2.91659 3.66634 1.99992C3.66634 1.08325 2.91634 0.333252 1.99967 0.333252C1.08301 0.333252 0.333008 1.08325 0.333008 1.99992C0.333008 2.91659 1.08301 3.66659 1.99967 3.66659ZM1.99967 5.33325C1.08301 5.33325 0.333008 6.08325 0.333008 6.99992C0.333008 7.91658 1.08301 8.66658 1.99967 8.66658C2.91634 8.66658 3.66634 7.91658 3.66634 6.99992C3.66634 6.08325 2.91634 5.33325 1.99967 5.33325ZM1.99967 10.3333C1.08301 10.3333 0.333008 11.0833 0.333008 11.9999C0.333008 12.9166 1.08301 13.6666 1.99967 13.6666C2.91634 13.6666 3.66634 12.9166 3.66634 11.9999C3.66634 11.0833 2.91634 10.3333 1.99967 10.3333Z"
      fill={fill}
    />
  </svg>
);

export default Dots;

import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  getSurvey,
  updateQuestions,
  updateQuestionTranslations,
  updateSurveyInfoTranslation,
} from "../../api/graphql/requests";
import { Spacer } from "../../common";
import { Header, InputsCard, QuestionEditorCard } from "../../components";
import { MAX_WIDTH } from "../../constants";
import { translate } from "../../i18n";
import { copySurveyLink } from "../../utils";

const UpdateSurveyScreen = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const translateKey = "updateSurveyScreen.";
  const { surveyId } = useParams();
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [languageId, setLanguageId] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const [survey, setSurvey] = useState();
  const [updatedQuestionTranslations, setUpdatedQuestionTranslations] =
    useState();
  useEffect(() => {
    setLanguageId(parseInt(searchParams.get("languageId")));
  }, [searchParams]);

  useEffect(() => {
    if (!surveyId || !languageId) {
      return;
    }
    (async () => {
      setSurvey(await getSurvey(surveyId, languageId));
    })();
  }, [surveyId, languageId]);

  useEffect(() => {
    if (!survey || !languageId) {
      return;
    }

    const surveyInfoTranslated = survey.infoTranslation;

    const surveyQuestionsTranslated = survey.questions.map(
      (question) => question.translation
    );

    setUpdatedQuestionTranslations(surveyQuestionsTranslated);
    setTitle(surveyInfoTranslated.title);
    setDescription(surveyInfoTranslated.description);
  }, [survey, languageId]);

  const handleSaveChanges = async () => {
    const surveyInfoTranslated = survey.infoTranslation;

    const res1 = await updateSurveyInfoTranslation({
      id: surveyInfoTranslated.id,
      title,
      description,
    });
    const res2 = await updateQuestions(
      survey.questions.map(({ id, isActive }) => ({ id, isActive }))
    );

    const res3 = await updateQuestionTranslations(
      updatedQuestionTranslations.map(({ id, title, kpiDescription }) => ({
        id,
        title,
        kpiDescription,
      }))
    );
    if (!res1 || !res2 || !res3) {
      console.log("something went wrong");
      return;
    }
    navigate(-1);
  };

  const handleOnUpdatedQuestionTranslationsChange = (
    newValue,
    field,
    translationId
  ) => {
    let questionTranslationCopy = [...updatedQuestionTranslations];
    questionTranslationCopy.forEach((translation, index) => {
      if (translation.id === translationId) {
        questionTranslationCopy[index][field] = newValue;
      }
    });
    setUpdatedQuestionTranslations(questionTranslationCopy);
  };

  const updateSurveyQuestionById = (id, toUpdate) => {
    setSurvey((prev) => ({
      ...prev,
      questions: prev.questions.map((question) =>
        question.id === id ? { ...question, ...toUpdate(question) } : question
      ),
    }));
  };

  const handleActiveChange = (questionId) => {
    updateSurveyQuestionById(questionId, (prevQuestion) => {
      return {
        isActive: !prevQuestion.isActive,
      };
    });
  };

  if (!survey || !languageId || !updatedQuestionTranslations) {
    return <div></div>;
  }

  return (
    <div className={classes.root}>
      <Header
        title={survey.surveyTime.translation.title}
        titleIcon="users"
        caption={survey.infoTranslation.description}
        ableToGoBack
        buttons={[
          {
            title: translate(translateKey + "addQuestionButton"),
            icon: "plusCircle",
            variant: "secondary",
            onClick: () => navigate("create-question"),
          },
          {
            title: translate(translateKey + "copyLinkButton"),
            variant: "secondary",
            icon: "link",
            onClick: () => copySurveyLink(survey.link, languageId),
          },
          {
            title: translate(translateKey + "saveChangesButton"),
            icon: "file",
            onClick: handleSaveChanges,
          },
        ]}
      />

      <Spacer spacing="large" />
      <div className={classes.viewContainer}>
        <div className={classes.listContainer}>
          <InputsCard
            inputs={[
              {
                value: title,
                onChange: setTitle,
                placeholder: translate(translateKey + "placeholderTitle"),
              },
              {
                value: description,
                onChange: setDescription,
                multiline: true,
                placeholder: translate(translateKey + "placeholderDescription"),
              },
            ]}
          />
          <Spacer spacing="small" />
          {survey.questions
            .sort((a, b) => a.id - b.id)
            .map((question) => (
              <div key={question.id.toString()}>
                <QuestionEditorCard
                  question={question}
                  languageId={languageId}
                  questionTranslation={updatedQuestionTranslations.find(
                    ({ id }) => id === question.translation.id
                  )}
                  onQuestionTranslationChange={(newValue, field) =>
                    handleOnUpdatedQuestionTranslationsChange(
                      newValue,
                      field,
                      question.translation.id
                    )
                  }
                  isActive={question.isActive}
                  onActiveChange={() => handleActiveChange(question.id)}
                />
                <Spacer spacing="small" />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  viewContainer: {
    width: "90%",
    maxWidth: MAX_WIDTH,
    display: "flex",
    justifyContent: "center",
  },
  listContainer: {
    display: "flex",
    flexDirection: "column",
    width: "70%",
  },
}));

export default UpdateSurveyScreen;

import theme from "../theme";

export default {
  common: {
    borderRadius: 4,
    margin: 16,
    marginSmall: 12,
    marginExtraSmall: 8,
    marginHorizontal: "5%",
    centering: {
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
    },
    border: {
      borderWidth: 1,
      borderColor: theme.palette.grey[5],
      borderStyle: "solid",
    },
  },
};

import { makeStyles } from "@material-ui/core";
import React from "react";
import { Spacer, Text } from "../../common";
import { DIMENS } from "../../constants";

import LoadingContainer from "../LoadingContainer/LoadingContainer";
const IMAGE_PATH = window.location.origin + "/images/sheetPlaceholderImage.png";

const Row = ({ cells }) => {
  const classes = useStylesRow();
  //each cell has a title and a color

  return (
    <div className={classes.container}>
      {cells.map(({ title, color, bold }, index) => (
        <div
          key={index.toString()}
          className={classes.cellContainer}
          style={{ backgroundColor: color }}
        >
          <Text variant="body" bold={bold} align="center">
            {title}
          </Text>
        </div>
      ))}
    </div>
  );
};
const useStylesRow = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    height: 40,
  },
  cellContainer: {
    ...DIMENS.common.centering,
    height: "100%",
    width: 280,
    borderWidth: 0.5,
    borderColor: theme.palette.grey[10],
    borderStyle: "solid",
  },
}));

const Placeholder = ({ title, image }) => {
  const classes = useStyles();
  return (
    <div className={classes.placeholderContainer}>
      <img
        src={image}
        className={classes.placeholderImage}
        alt="sheetPlaceholderImage"
      />
      <Spacer spacing="medium" />
      <Text variant="h3" color="grey40">
        {title}
      </Text>
    </div>
  );
};

const Sheet = ({
  rows,
  loading,
  placeholderTitle,
  placeholderImage = IMAGE_PATH,
}) => {
  const classes = useStyles();
  if (loading) {
    return <LoadingContainer />;
  }
  if (!rows || rows.length === 0) {
    return <Placeholder title={placeholderTitle} image={placeholderImage} />;
  }

  return (
    <div className={classes.container}>
      {rows.map((row, index) => (
        <Row cells={row} key={index.toString()} />
      ))}
    </div>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  placeholderImage: {
    width: 62,
    height: 185,
  },
  placeholderContainer: {
    width: "100%",
    height: "100%",
    flexDirection: "column",
    ...DIMENS.common.centering,
  },
}));

export default Sheet;

import {
  FormControl,
  makeStyles,
  OutlinedInput,
  Select,
} from "@material-ui/core";
import React from "react";
import { SPACING } from "../../constants";
import { translate } from "../../i18n";
import Checkbox from "../Checkbox/Checkbox";
import RadioButton from "../RadioButton/RadioButton";
import Spacer from "../Spacer/Spacer";
import Text from "../Text/Text";

const ITEM_HEIGHT = 40;
const CHECKBOX_SIZE = { width: 15, height: 15 };

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 8,
    },
  },
  getContentAnchorEl: null,
};

const Dropdown = ({ multiple, value, onChange, items, placeholder }) => {
  const classes = useStyles();
  const outlinedInputClasses = useOutlinedInputStyles();

  const valueHasDuplicates = (value) => {
    const allObjects = value.filter((id) => typeof id === "object");
    if (allObjects.filter(({ id }) => value.includes(id)).length > 0) {
      return true;
    }
    return false;
  };

  const handleChange = (e) => {
    const {
      target: { value: newValue },
    } = e;

    if (Array.isArray(newValue)) {
      const valueAlreadyExist = valueHasDuplicates(newValue);
      if (valueAlreadyExist) {
        onChange((prev) => prev?.filter(({ id }) => !newValue.includes(id)));
        return;
      }
      onChange((prev) => [
        ...prev,
        ...items.filter(({ id }) => newValue.includes(id)),
      ]);
      return;
    }
    onChange(items.find(({ id }) => id === newValue));
  };

  const allIsChecked = () => value.length === items.length;

  const handleChooseAll = () => {
    if (allIsChecked()) {
      onChange([]);
      return;
    }
    onChange(items);
  };

  const getSortedItems = (items) =>
    items
      .sort((a, b) => a.id - b.id)
      .map(({ title }) => title)
      .join(", ");

  const isCheckboxChecked = (value, item) =>
    Array.isArray(value)
      ? value?.map(({ id }) => id).includes(item.id)
      : value?.id === item.id;

  return (
    <div className={classes.root}>
      <FormControl className={classes.formControl}>
        <Select
          displayEmpty
          multiple={multiple}
          value={value}
          onChange={handleChange}
          input={
            <OutlinedInput
              className={classes.outlinedInput}
              classes={outlinedInputClasses}
            />
          }
          renderValue={() => {
            if (Array.isArray(value) && value.length > 0) {
              return getSortedItems(value);
            }
            if (value && value.title) {
              return value.title;
            }
            return (
              <Text variant="caption" color="grey30">
                {placeholder}
              </Text>
            );
          }}
          MenuProps={MenuProps}
        >
          {multiple && (
            <div className={classes.itemContainer}>
              <Checkbox
                checked={allIsChecked()}
                onClick={handleChooseAll}
                className={classes.checkbox}
              />
              <Spacer spacing="small" direction="horizontal" />
              <Text variant="caption" color="grey90">
                {translate("dropdown.chooseAll")}
              </Text>
            </div>
          )}
          {items.map((item) => (
            <div
              key={item.id}
              value={item.id}
              className={classes.itemContainer}
            >
              {multiple ? (
                <Checkbox
                  checked={isCheckboxChecked(value, item)}
                  className={classes.checkbox}
                />
              ) : (
                <RadioButton checked={isCheckboxChecked(value, item)} />
              )}
              <Spacer spacing="small" direction="horizontal" />
              <Text variant="caption" color="grey90">
                {item.title}
              </Text>
            </div>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

const useOutlinedInputStyles = makeStyles((theme) => ({
  root: {
    "& $notchedOutline": {
      borderColor: theme.palette.grey[30],
    },
    "&:hover $notchedOutline": {
      borderColor: theme.palette.grey[30],
    },
    "&$focused $notchedOutline": {
      borderColor: theme.palette.grey[30],
      borderWidth: 1,
    },
  },
  focused: {},
  notchedOutline: {},
}));

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  outlinedInput: {
    borderRadius: 4,
    borderColor: "blue",
    height: "100%",
    color: theme.palette.grey[90],
    fontSize: 14,
    fontWeight: 400,
    width: "100%",
  },
  formControl: {
    width: "100%",
    height: ITEM_HEIGHT,
  },

  itemContainer: {
    borderBottomStyle: "solid",
    borderBottomColor: theme.palette.grey[5],
    borderBottomWidth: 1,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingLeft: SPACING.small,
    paddingRight: SPACING.small,
    height: ITEM_HEIGHT,
    width: "100%",
  },
  tagContainer: {
    width: "100%",
    marginLeft: SPACING.medium,
    marginBottom: SPACING.tiny,
    marginTop: SPACING.small,
  },
  checkbox: {
    ...CHECKBOX_SIZE,
  },
}));

export default Dropdown;

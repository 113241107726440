import { URLS } from "../../constants";
export * from "./image";

const HEADERS = {
  Accept: "application/json",
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": true,
};

export const sendResetPasswordMail = async (email) => {
  try {
    let res = await fetch(`${URLS.BACKEND}/reset-password`, {
      method: "POST",
      body: JSON.stringify({ email }),
      headers: HEADERS,
    });
    res = await res.json();
    return res;
  } catch (e) {
    console.log(e);
  }
};

export const updatePassword = async (token, password) => {
  try {
    let res = await fetch(`${URLS.BACKEND}/update-password`, {
      method: "POST",
      body: JSON.stringify({ token, password }),
      headers: HEADERS,
    });
    res = await res.json();
    return res;
  } catch (e) {
    console.log(e);
  }
};

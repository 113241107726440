import { makeStyles } from "@material-ui/core";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Icon, IconButton, Spacer, Text } from "../../common";
import { MAX_WIDTH, SPACING } from "../../constants";
import theme from "../../theme";
import Logo from "../Logo/Logo";
import ProfileButton from "../ProfileButton/ProfileButton";
import Tabs from "../Tabs/Tabs";

const Path = ({ path }) => {
  const classes = useStylesPath();
  //path is a list such as ["Start", "IKEA"]
  return (
    <div className={classes.root}>
      {path.map((text, index) => (
        <div className={classes.itemContainer} key={index.toString()}>
          {index === 0 ? (
            <></>
          ) : index === path.length - 1 ? (
            <div style={{ opacity: "30%" }}>
              <Text>/</Text>
            </div>
          ) : (
            <Text>/</Text>
          )}
          <div
            className={classes.itemText}
            style={{ opacity: index === path.length - 1 ? "30%" : "100%" }}
          >
            <Text>{text}</Text>
          </div>
        </div>
      ))}
    </div>
  );
};

const useStylesPath = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  itemText: {
    paddingLeft: SPACING.medium,
    paddingRight: SPACING.medium,
  },
  itemContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
}));

const Header = ({
  tabLabels,
  tabValue,
  setTabValue,
  ableToGoBack,
  path,
  buttons,
  header,
  titleIcon,
  title,
  caption,
  showProfileButton,
  showLogo,
  email,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div
          className={classes.leftContainer}
          style={{ justifyContent: !title && "center" }}
        >
          {(title || tabLabels) && <Spacer spacing="large" />}
          <div className={classes.leftUpperContainer}>
            {ableToGoBack && (
              <IconButton icon="back" onClick={() => navigate(-1)} />
            )}
            <Spacer spacing="small" direction="horizontal" />
            {path && <Path path={path} />}
            {header && (
              <Text variant="h3" bold color="black">
                {header}
              </Text>
            )}
            {titleIcon && (
              <Icon
                name={titleIcon}
                size="large"
                fill={theme.palette.primary.tintDark}
              />
            )}
            {titleIcon && title && (
              <Spacer spacing="small" direction="horizontal" />
            )}
            {title && (
              <Text variant="h3" color="black">
                {title}
              </Text>
            )}
          </div>

          {caption && (
            <>
              <div className={classes.captionContainer}>
                <Text variant="body">{caption}</Text>
              </div>
              <Spacer spacing="large" />
            </>
          )}
          {showLogo && <Logo size="medium" />}
          {/*header && (
            <>
              <Text variant="h2Semi">{header}</Text>
              <Spacer spacing="medium" />
            </>
          )*/}
          {tabLabels && (
            <div className={classes.tabContainer}>
              <Spacer spacing="large" />
              <Tabs
                labels={tabLabels}
                value={tabValue}
                onChange={setTabValue}
              />
            </div>
          )}
        </div>

        <div className={classes.rightContainer}>
          {showProfileButton && <ProfileButton />}
          {buttons &&
            buttons.map(
              (
                {
                  onClick,
                  icon,
                  title,
                  iconSize = "medium",
                  variant,
                  disabled,
                  visible = true,
                },
                index
              ) =>
                visible && (
                  <div
                    className={classes.buttonContainer}
                    key={index.toString()}
                  >
                    {index !== 0 && (
                      <Spacer spacing="large" direction="horizontal" />
                    )}
                    <Button
                      variant={variant}
                      onClick={onClick}
                      icon={icon}
                      title={title}
                      iconSize={iconSize}
                      disabled={disabled}
                    />
                  </div>
                )
            )}
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    backgroundColor: theme.palette.white,
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1);",
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
    width: "90%",
    flexDirection: "row",
    maxWidth: MAX_WIDTH,
  },

  leftContainer: {
    display: "flex",
    flexDirection: "column",
  },
  leftUpperContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
  },
  rightContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    paddingBottom: SPACING.large,
    paddingTop: SPACING.large,
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
  },
  contentContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
  },
  tabContainer: {
    justifySelf: "flex-end",
  },
  captionContainer: {
    maxWidth: 250,
    marginLeft: 36 + SPACING.small,
  },
}));

export default Header;

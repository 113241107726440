const Check = ({ fill, iconSize, style }) => (
  <svg
    width={iconSize}
    height={iconSize}
    viewBox="0 0 24 17"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.59979 13.56L2.97313 8.93334C2.45313 8.41334 1.61312 8.41334 1.09312 8.93334C0.573125 9.45334 0.573125 10.2933 1.09312 10.8133L6.66646 16.3867C7.18646 16.9067 8.02646 16.9067 8.54646 16.3867L22.6531 2.28001C23.1731 1.76001 23.1731 0.92001 22.6531 0.40001C22.1331 -0.11999 21.2931 -0.11999 20.7731 0.40001L7.59979 13.56Z"
      fill={fill}
    />
  </svg>
);

export default Check;

import { makeStyles } from "@material-ui/core";
import React from "react";
import { Text } from "../../common";
import { DIMENS, SPACING } from "../../constants";

const ITEM_SIZE = { width: 42, height: 42 };

const ProfileIcon = ({ letter }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Text variant="h2" bold color="secondary">
        {letter.toUpperCase()}
      </Text>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.primary.main,
    padding: SPACING.small,
    ...ITEM_SIZE,
    borderRadius: "50%",
    ...DIMENS.common.centering,
  },
}));

export default ProfileIcon;

import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { createAnswers, getSurveyByLink } from "../../api/graphql/requests";
import { Button, Spacer } from "../../common";
import { QuestionCard, Slogan, SurveyInfoCard } from "../../components";
import { DIMENS, MAX_WIDTH } from "../../constants";
import { changeLanguage, translate } from "../../i18n";
import theme from "../../theme";

const SurveyScreen = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const translateKey = "surveyScreen.";
  const { link } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [survey, setSurvey] = useState();
  const [loading, setLoading] = useState(false);
  const [languageId, setLanguageId] = useState();
  //questionAnswers = array where each value is the answerAlternativeId
  const [questionAnswers, setQuestionAnswers] = useState();

  useEffect(() => {
    setLanguageId(parseInt(searchParams.get("languageId")));
  }, [searchParams]);

  useEffect(() => {
    if (!link || !languageId) {
      return;
    }
    (async () => {
      const survey = await getSurveyByLink(link, languageId);
      const choicesQuestions = survey.questions.filter(
        (question) => question.answerType.id === 1
      );
      const scaleQuestions = survey.questions.filter(
        (question) => question.answerType.id === 2
      );
      setSurvey({
        ...survey,
        questions: [...choicesQuestions, ...scaleQuestions],
      });
    })();
  }, [link, languageId]);

  useEffect(() => {
    if (!survey) {
      return;
    }
    setQuestionAnswers(Array(survey.questions.length).fill());
  }, [survey]);

  useEffect(() => {
    changeLanguage(languageId);
  }, [languageId]);

  const handleQuestionAnswersChange = ({ id: answerId }, questionIndex) => {
    let questionAnswersCopy = [...questionAnswers];
    questionAnswersCopy[questionIndex] = answerId;
    setQuestionAnswers(questionAnswersCopy);
  };
  const handleSendAnswers = async () => {
    setLoading(true);
    const answersList = questionAnswers.map((answerAlternativeId, index) => ({
      answerAlternativeId,
      questionId: survey.questions[index].id,
    }));

    const res = await createAnswers(answersList, survey.id);

    if (!res) {
      setLoading(false);
      return;
    }
    setLoading(false);
    navigate("sent?" + new URLSearchParams({ languageId }));
  };

  if (!survey || !languageId || !questionAnswers) {
    return <div />;
  }

  return (
    <div
      className={classes.root}
      style={{
        backgroundColor: "#F7F7F7",
      }}
    >
      <div className={classes.viewContainer}>
        <div className={classes.listContainer}>
          <Spacer spacing={60} />
          <img
            src={survey.employeeGroup.customer.coverImage}
            className={classes.coverImage}
            alt="coverImage"
          />
          <Spacer spacing="small" />
          <SurveyInfoCard survey={survey} languageId={languageId} />
          {survey.questions.map((question, index) => (
            <div
              key={question.id.toString()}
              className={classes.questionCardContainer}
            >
              <Spacer spacing="small" />
              <QuestionCard
                question={question}
                survey={survey}
                accentColor={survey.employeeGroup.customer.accentColor.main}
                value={{ id: questionAnswers[index] }}
                onChange={(newValue) =>
                  handleQuestionAnswersChange(newValue, index)
                }
              />
            </div>
          ))}
          <Spacer spacing="large" />
          <Button
            color={survey.employeeGroup.customer.accentColor.main}
            title={translate(translateKey + "sendButton")}
            disabled={questionAnswers.includes(undefined) || loading}
            onClick={handleSendAnswers}
            loading={loading}
          />
          <Spacer spacing={60} />
        </div>
      </div>
      <div className={classes.bottomContainer}>
        <Slogan />
      </div>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
    overflow: "auto",
  },
  viewContainer: {
    width: "90%",
    maxWidth: MAX_WIDTH,
    "@media (max-width:1100px)": { width: "95%" },
    display: "flex",
    justifyContent: "center",
  },
  listContainer: {
    width: "60%",
    "@media (max-width:1100px)": { width: "100%" },
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  coverImage: {
    width: "100%",
    objectFit: "cover",
    borderRadius: DIMENS.common.borderRadius,
    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.05);",
    "@media (max-width:900px)": { display: "none" },
  },
  bottomContainer: {
    height: 90,
    backgroundColor: theme.palette.white,
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  questionCardContainer: {
    width: "100%",
  },
}));

export default SurveyScreen;

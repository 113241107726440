import { makeStyles } from "@material-ui/core";
import React from "react";
import { Spacer, Text } from "../../common";
import { DIMENS } from "../../constants";

import FloatingContainer from "../FloatingContainer/FloatingContainer";

const SurveyInfoCard = ({ survey, languageId, hasShadow = true }) => {
  const surveyInfoTranslated = survey.infoTranslation;
  const classes = useStyles();

  const getTextsContainerStyle = () =>
    surveyInfoTranslated.description
      ? classes.textsContainerDefault
      : classes.textsContainerOnlyTitle;

  return (
    <FloatingContainer className={classes.container} hasShadow={hasShadow}>
      <img
        src={survey.employeeGroup.customer.logo}
        className={classes.logo}
        alt="companyLogo"
      />

      <Spacer spacing="medium" direction="horizontal" />
      <div className={getTextsContainerStyle()}>
        <Text variant="h3">{surveyInfoTranslated.title}</Text>
        {surveyInfoTranslated.description && (
          <Text variant="body">{surveyInfoTranslated.description}</Text>
        )}
        <Spacer spacing="medium" direction="horizontal" />
      </div>
    </FloatingContainer>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  logo: {
    maxWidth: 200,
    objectFit: "cover",
    borderRadius: DIMENS.common.borderRadius,
  },
  textsContainerDefault: {
    display: "flex",
    flexDirection: "column",
  },
  textsContainerOnlyTitle: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
}));

export default SurveyInfoCard;

import { setContext } from "@apollo/client/link/context";
import { InMemoryCache } from "apollo-cache-inmemory";
import ApolloClient from "apollo-client";
import { HttpLink } from "apollo-link-http";
import { HEADER_KEYS, URLS } from "../../constants";

const httpLink = new HttpLink({
  uri: `${URLS.BACKEND}/graphql`,
  headers: {
    "content-type": "application/json",
  },
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("token");

  return {
    headers: {
      ...headers,
      [HEADER_KEYS.JWT_TOKEN]: token,
    },
  };
});

const defaultOptions = {
  query: {
    fetchPolicy: "no-cache",
  },
};

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({ addTypename: false }),
  defaultOptions,
});

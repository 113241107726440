import { makeStyles } from "@material-ui/core";
import React from "react";
import { Checkbox, Input, Spacer, Text } from "../../common";
import { DIMENS, SPACING } from "../../constants";
import { translate } from "../../i18n";
import theme from "../../theme";
import RadioButtonList from "../RadioButtonList/RadioButtonList";

const translateKey = "questionEditorCard.";

const ScalePreview = ({ scaleTop, scaleBottom }) => {
  const classes = useScalePreviewStyles();
  return (
    <div className={classes.container}>
      <div className={classes.innerContainer}>
        <div className={classes.numberContainer}>
          <Text variant="h3" bold>
            {scaleTop}
          </Text>
        </div>
        <Spacer spacing="extraLarge" direction="horizontal" />
        <Text variant="body">
          {translate(translateKey + "scaleTopDescription")}
        </Text>
      </div>
      <Spacer spacing="extraLarge" direction="horizontal" />
      <div className={classes.innerContainer}>
        <div className={classes.numberContainer}>
          <Text variant="h3" bold>
            {scaleBottom}
          </Text>
        </div>
        <Spacer spacing="extraLarge" direction="horizontal" />
        <Text variant="body">
          {translate(translateKey + "scaleBottomDescription")}
        </Text>
      </div>
    </div>
  );
};

const useScalePreviewStyles = makeStyles(() => ({
  container: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
  },
  innerContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  numberContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 1,
  },
}));

const QuestionEditorCard = ({
  question,
  questionTranslation,
  onQuestionTranslationChange,
  onActiveChange,
  isActive,
}) => {
  const questionTranslated = question.translation;
  const kpiTranslated = question.kpi.translation;

  const answerAlernativesTranslated = question.answerAlternatives.map(
    (alternative) => alternative.translation
  );

  const getBottomComponent = () => {
    switch (question.answerType.title) {
      case "scale":
        return (
          <ScalePreview
            scaleBottom={question.scale[0]}
            scaleTop={question.scale[1]}
          />
        );
      case "choices":
        return <RadioButtonList disabled items={answerAlernativesTranslated} />;
      default:
        break;
    }
  };

  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.upperContainer}>
        <div className={classes.kpiContainer}>
          <Text variant="smallText" color="white">
            {kpiTranslated.title}
          </Text>
        </div>
        <Spacer spacing="medium" direction="horizontal" />
        <Input
          placeholder={questionTranslated.kpiDescription}
          value={questionTranslation.kpiDescription}
          onChange={(newValue) =>
            onQuestionTranslationChange(newValue, "kpiDescription")
          }
        />
      </div>
      <Spacer spacing={40} />
      <Input
        value={questionTranslation.title}
        onChange={(newValue) => onQuestionTranslationChange(newValue, "title")}
      />
      <Spacer spacing={40} />
      <div className={classes.bottomComponentContainer}>
        {getBottomComponent()}
        <div className={classes.bottomComponentRightContainer}>
          <div className={classes.isActiveContainer}>
            <Text>{translate(translateKey + "isActive")}</Text>
            <Checkbox checked={isActive} onClick={onActiveChange} />
          </div>
        </div>
      </div>
      <Spacer spacing="large" />
    </div>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    width: "100%",
    paddingLeft: SPACING.large,
    paddingRight: SPACING.large,
    paddingTop: 45,
    borderRadius: DIMENS.common.borderRadius,
    ...DIMENS.common.border,
  },
  upperContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  kpiContainer: {
    paddingTop: SPACING.tiny,
    paddingBottom: SPACING.tiny,
    paddingLeft: SPACING.medium,
    paddingRight: SPACING.medium,
    borderRadius: 100,
    backgroundColor: theme.palette.primary.main,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  bottomComponentContainer: {
    display: "flex",
    flexDirection: "row",
    paddingLeft: SPACING.medium,
    justifyContent: "space-between",
  },
  bottomComponentRightContainer: {
    alignSelf: "flex-end",
  },
  isActiveContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
}));

export default QuestionEditorCard;

import { useState, useEffect } from "react";
import { getAccentColors } from "../api/graphql/requests";

const useAccentColors = () => {
  const [accentColors, setAccentColors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchAccentColors = async () => {
      setLoading(true);
      setLoading(false);
      try {
        const data = await getAccentColors();
        setAccentColors(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setError(true);
      }
    };

    fetchAccentColors();

    return () => {};
  }, []);

  return [accentColors, loading, error];
};

export default useAccentColors;

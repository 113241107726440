import { makeStyles } from "@material-ui/core";
import React from "react";
import { DIMENS, SPACING } from "../../constants";

const FloatingContainer = ({ children, className, hasShadow }) => {
  const classes = useStyles();
  return (
    <div
      style={{
        boxShadow: hasShadow ? "0px 0px 20px rgba(0, 0, 0, 0.05);" : "none",
      }}
      className={[classes.container, className].join(" ")}
    >
      {children}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    ...DIMENS.common.border,
    width: "100%",
    padding: SPACING.large,
    borderRadius: DIMENS.common.borderRadius,
    backgroundColor: theme.palette.white,
  },
}));
export default FloatingContainer;

const LogOut = ({ fill, iconSize, style }) => (
  <svg
    width={iconSize}
    height={iconSize}
    viewBox="0 0 17 18"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.5 2.4375C0.5 1.22938 1.47938 0.25 2.6875 0.25H5.8125C6.33026 0.25 6.75 0.669738 6.75 1.1875C6.75 1.70526 6.33026 2.125 5.8125 2.125H2.6875C2.51491 2.125 2.375 2.26491 2.375 2.4375V15.5625C2.375 15.7351 2.51491 15.875 2.6875 15.875H5.8125C6.33026 15.875 6.75 16.2948 6.75 16.8125C6.75 17.3302 6.33026 17.75 5.8125 17.75H2.6875C1.47938 17.75 0.5 16.7706 0.5 15.5625V2.4375ZM13.5491 8.0625H6.43752C5.91976 8.0625 5.50002 8.48224 5.50002 9C5.50002 9.51777 5.91976 9.9375 6.43752 9.9375H13.5491L11.0871 12.3996C10.721 12.7657 10.721 13.3593 11.0871 13.7254C11.4532 14.0915 12.0468 14.0915 12.4129 13.7254L16.4754 9.66291C16.8415 9.2968 16.8415 8.7032 16.4754 8.33709L12.4129 4.27459C12.0468 3.90847 11.4532 3.90847 11.0871 4.27459C10.721 4.6407 10.721 5.2343 11.0871 5.60041L13.5491 8.0625Z"
      fill={fill}
    />
  </svg>
);

export default LogOut;

import { makeStyles } from "@material-ui/core";
import React from "react";
import { Button, Icon, Spacer, Text } from "../../common";
import { DIMENS, SPACING } from "../../constants";
import theme from "../../theme";

const Card = ({ icon, button, title, description, onClick }) => {
  const classes = useStyles({ onClick });
  return (
    <div
      className={classes.container}
      onClick={onClick}
      style={{ cursor: onClick && "pointer" }}
    >
      <div className={classes.upperContainer}>
        <Icon name={icon} size="large" fill={theme.palette.primary.tintDark} />
        <Spacer spacing="small" direction="horizontal" />
        <Text variant="h3" color="grey90">
          {title}
        </Text>
      </div>
      <Spacer spacing="large" />
      <Button
        onClick={(e) => {
          e.stopPropagation();
          button.onClick();
        }}
        icon={button.icon}
        variant={button.variant}
        disabled={button.disabled}
        title={button.title}
      />
    </div>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    width: 280,
    ...DIMENS.common.border,
    borderRadius: DIMENS.common.borderRadius,
    paddingLeft: SPACING.medium,
    paddingBottom: SPACING.small,
    paddingTop: SPACING.medium,
    paddingRight: SPACING.medium,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  upperContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
  },
}));
export default Card;

const Close = ({ fill, iconSize, style }) => (
  <svg
    width={iconSize}
    height={iconSize}
    viewBox="0 0 12 11"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.2501 0.258431C10.9251 -0.0665688 10.4001 -0.0665688 10.0751 0.258431L6.0001 4.3251L1.9251 0.250098C1.6001 -0.0749023 1.0751 -0.0749023 0.750098 0.250098C0.425098 0.575098 0.425098 1.1001 0.750098 1.4251L4.8251 5.5001L0.750098 9.5751C0.425098 9.9001 0.425098 10.4251 0.750098 10.7501C1.0751 11.0751 1.6001 11.0751 1.9251 10.7501L6.0001 6.6751L10.0751 10.7501C10.4001 11.0751 10.9251 11.0751 11.2501 10.7501C11.5751 10.4251 11.5751 9.9001 11.2501 9.5751L7.1751 5.5001L11.2501 1.4251C11.5668 1.10843 11.5668 0.575098 11.2501 0.258431Z"
      fill={fill}
    />
  </svg>
);

export default Close;

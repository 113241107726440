const Upload = ({ fill, iconSize, style }) => (
  <svg
    width={iconSize}
    height={iconSize}
    viewBox="0 0 15 18"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.5 13.5H9.5C10.05 13.5 10.5 13.05 10.5 12.5V7.49997H12.09C12.98 7.49997 13.43 6.41997 12.8 5.78997L8.21 1.19997C7.82 0.809971 7.19 0.809971 6.8 1.19997L2.21 5.78997C1.58 6.41997 2.02 7.49997 2.91 7.49997H4.5V12.5C4.5 13.05 4.95 13.5 5.5 13.5ZM1.5 15.5H13.5C14.05 15.5 14.5 15.95 14.5 16.5C14.5 17.05 14.05 17.5 13.5 17.5H1.5C0.95 17.5 0.5 17.05 0.5 16.5C0.5 15.95 0.95 15.5 1.5 15.5Z"
      fill={fill}
    />
  </svg>
);

export default Upload;

import DIMENS from "./dimensions";
import SPACING from "./spacing";
import URLS from "./urls";
import HEADER_KEYS from "./headerKeys";
import IMAGE_SIZES from "./imageSizes";

const DEFAULT_LANGUAGE_ID = 1;
const MAX_WIDTH = 1230;

export {
  DIMENS,
  SPACING,
  URLS,
  HEADER_KEYS,
  IMAGE_SIZES,
  MAX_WIDTH,
  DEFAULT_LANGUAGE_ID,
};

import { makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import ImageUploading from "react-images-uploading";
import { Icon, IconButton, Popover, Spacer, Text } from "../../common";
import { DIMENS } from "../../constants";
import { translate } from "../../i18n";
import theme from "../../theme";

const UPLOAD_VARIANT = {
  SQUARE: "square",
};

const SQUARE_SIZE = { width: "100%", height: 190 };
const DEFAULT_SIZE = { width: "100%", height: 270 };

const Upload = ({
  title = "Upload image",
  icon = "upload",
  onImageChange,
  image,
  variant,
}) => {
  const classes = useStyles();
  const [imageList, setImageList] = useState(image && [{ dataURL: image }]);
  const [iconButtonAnchor, setIconButtonAnchor] = useState();
  const translateKey = "upload.";
  const getRootStyle = () => {
    switch (variant) {
      case UPLOAD_VARIANT.SQUARE:
        return classes.rootSquare;

      default:
        return classes.root;
    }
  };
  return (
    <ImageUploading
      value={imageList}
      multiple
      onChange={(imageList) => {
        const imageFile = imageList.length > 0 && imageList[0].file;
        onImageChange(imageFile);
        setImageList(imageList);
      }}
    >
      {({ onImageUpload, dragProps, onImageRemoveAll, onImageUpdate }) => (
        <div className={getRootStyle()}>
          {(!imageList || imageList.length === 0) && (
            <div
              className={classes.uploadContainer}
              onClick={onImageUpload}
              {...dragProps}
            >
              <div className={classes.innerContainer}>
                <Icon name={icon} fill={theme.palette.grey[80]} />
                <Spacer direction="horizontal" spacing="medium" />
                <Text variant="smallText" color="grey80">
                  {title}
                </Text>
              </div>
            </div>
          )}

          {imageList && imageList.length > 0 && imageList[0]["dataURL"] && (
            <>
              <div className={classes.iconButton}>
                <IconButton
                  icon="dots"
                  variant="circular"
                  onClick={(e) => setIconButtonAnchor(e.currentTarget)}
                />
              </div>
              <Popover
                items={[
                  {
                    text: translate(translateKey + "change"),
                    icon: "photo",
                    onClick: () => {
                      onImageUpdate(0);
                    },
                  },
                  {
                    text: translate(translateKey + "remove"),
                    icon: "remove",
                    onClick: onImageRemoveAll,
                  },
                ]}
                anchorEl={iconButtonAnchor}
                setAnchorEl={setIconButtonAnchor}
              />
              <img
                src={imageList[0]["dataURL"]}
                alt="uploadImage"
                className={classes.image}
              />
            </>
          )}
        </div>
      )}
    </ImageUploading>
  );
};

const useStyles = makeStyles((theme) => ({
  uploadContainer: {
    borderWidth: 1,
    backgroundColor: theme.palette.grey[5],
    borderRadius: DIMENS.common.borderRadius,
    borderColor: theme.palette.grey[10],
    borderStyle: "solid",
    height: "100%",
    width: "100%",
    cursor: "pointer",
    ...DIMENS.common.centering,
  },
  rootSquare: {
    ...SQUARE_SIZE,
    position: "relative",
  },
  root: {
    ...DEFAULT_SIZE,
    position: "relative",
  },
  innerContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  image: {
    maxWidth: "100%",
    maxHeight: "100%",
    objectFit: "cover",
    borderRadius: DIMENS.common.borderRadius,
  },
  iconButton: {
    position: "absolute",
    right: -10,
    top: -10,
  },
}));

export default Upload;

import { makeStyles } from "@material-ui/core";
import React from "react";
import { Input, Spacer, Text } from "../../common";
import { DIMENS, SPACING } from "../../constants";
import { translate } from "../../i18n";
import theme from "../../theme";

const InputsCard = ({ inputs }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Spacer spacing={45} />
      {inputs.map(({ placeholder, value, onChange, multiline }, index) => (
        <div key={index.toString()}>
          {index !== 0 && <Spacer spacing={40} />}
          <Input
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            multiline={multiline}
          />
        </div>
      ))}
      <Spacer spacing={50} />
    </div>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    width: "100%",
    paddingLeft: SPACING.large,
    paddingRight: SPACING.large,
    borderRadius: DIMENS.common.borderRadius,
    ...DIMENS.common.border,
  },
}));

export default InputsCard;

import { makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Button, Spacer } from "../../common";
import { AddKpiModal, List } from "../../components";
import { translate } from "../../i18n";
import { createKPI } from "../../api/graphql/requests";
import { toast } from "react-toastify";

const CustomersView = ({ customers, loading }) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [kpiModalIsOpen, setKpiModalIsOpen] = useState(false);

  const addHeadersAlphabeticallyByTitleIndex = (items, titleIndex) => {
    let itemsWithHeaders = [];
    items.forEach((item, index) => {
      const curTitle = item.titles[titleIndex];
      const prevTitle =
        index === 0 ? "empty" : items[index - 1].titles[titleIndex];
      if (index === 0 || curTitle[0] !== prevTitle[0]) {
        itemsWithHeaders.push({ header: curTitle[0].toUpperCase() });
      }
      itemsWithHeaders.push(item);
    });

    return itemsWithHeaders;
  };

  const openKPIModal = () => {
    setKpiModalIsOpen(true);
  };
  const handleNavigateToCreateCustomer = () => {
    navigate(`customer/create`);
  };

  const getListItemsFromCustomers = () => {
    let items = [];
    customers.forEach(({ branch, companyName, employeesCount, id }, index) => {
      items.push({
        titles: [companyName, employeesCount?.title, branch?.title],
        onClick: () => navigate(`customer/${id}`),
      });
    });
    items = addHeadersAlphabeticallyByTitleIndex(items, 0);

    return items;
  };

  const handleCreateKPI = async (kpi) => {
    const res = await createKPI(kpi);
    if (!res) {
      toast.error(translate("customersView.errorCreateKPI"));
      return;
    }
    toast.success(translate("customersView.successCreateKPI"));
    setKpiModalIsOpen(false);
  };

  return (
    <>
      <div className={classes.root}>
        <div className={classes.buttonContainer}>
          <Button
            title={translate("customersView.createKPIButton")}
            icon="plusCircle"
            iconSize="medium"
            onClick={openKPIModal}
            variant="secondary"
          />
          <Spacer direction="horizontal" />
          <Button
            title={translate("customersView.createCustomerButton")}
            icon="plusCircle"
            iconSize="medium"
            onClick={handleNavigateToCreateCustomer}
          />
        </div>

        {customers && customers.length > 0 && (
          <List items={getListItemsFromCustomers()} loading={loading} />
        )}
      </div>
      <AddKpiModal
        onCreateKPI={handleCreateKPI}
        isOpen={kpiModalIsOpen}
        onClose={() => setKpiModalIsOpen(false)}
      />
    </>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  buttonContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
}));

export default CustomersView;

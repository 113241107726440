import React from "react";
import { SPACING } from "../../constants";

const Spacer = ({ spacing = "medium", direction = "vertical" }) => {
  return (
    <div
      style={
        direction === "vertical"
          ? {
              height: SPACING[spacing] || spacing,
              width: "100%",
              minHeight: SPACING[spacing] || spacing,
            }
          : {
              height: "100%",
              width: SPACING[spacing] || spacing,
              minWidth: SPACING[spacing] || spacing,
            }
      }
    />
  );
};

export default Spacer;

import React, { useContext, useEffect, useState } from "react";
import { authenticate, signIn } from "../api/graphql/requests";
import { LoadingView } from "../views";

const LOCAL_STORAGE_KEYS = {
  TOKEN: "token",
};

const AuthContext = React.createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);

  const logIn = async (email, password) => {
    const { token, user } = (await signIn(email, password)) || {};
    if (!token) {
      console.log("login failed");
      return false;
    }
    localStorage.setItem(LOCAL_STORAGE_KEYS.TOKEN, token);
    setCurrentUser(user);
    return true;
  };

  /*const createAccount = async (email, password) => {
    const { token, userId } = (await signUp(email, password)) || {};
    if (!token) {
      console.log("signup failed");
      return false;
    }
    localStorage.setItem(LOCAL_STORAGE_KEYS.TOKEN, token);
    setCurrentUser(userId);
    return true;
  };*/

  const logOut = async () => {
    localStorage.removeItem(LOCAL_STORAGE_KEYS.TOKEN);
    setCurrentUser(false);
  };

  const authenticateUser = async () => {
    const { token: newToken, user } = (await authenticate()) || {};
    if (!newToken) {
      console.log("authentication failed");
      return false;
    }
    localStorage.setItem(LOCAL_STORAGE_KEYS.TOKEN, newToken);
    setCurrentUser(user);
    return true;
  };

  useEffect(() => {
    (async () => {
      await authenticateUser();
      setLoading(false);
    })();
  }, []);

  const value = {
    currentUser,
    logIn,
    logOut,
  };

  if (loading) {
    return <LoadingView />;
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { updatePassword } from "../../api/functions";
import { Button, Input, Spacer } from "../../common";
import { Logo } from "../../components";
import { DIMENS } from "../../constants";
import { translate } from "../../i18n";

const UpdatePasswordScreen = () => {
  const translateKey = "updatePasswordScreen.";
  const classes = useStyles();
  const [searchParams, setSearchParams] = useSearchParams();
  const [token, setToken] = useState();
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    setToken(searchParams.get("token"));
  }, [searchParams]);

  const handleUpdatePassword = async () => {
    const res = await updatePassword(token, password);
    if (!res) {
      return;
    }
    navigate("login", { replace: true });
  };

  if (!token) {
    return <></>;
  }

  return (
    <div className={classes.root}>
      <div className={classes.innerContainer}>
        <Logo size="large" />
        <Spacer spacing={70} />
        <Input
          value={password}
          onChange={setPassword}
          type="password"
          placeholder={translate(translateKey + "placeholderPassword")}
        />
        <Spacer spacing="large" />
        <Button
          title={translate(translateKey + "buttonUpdate")}
          onClick={handleUpdatePassword}
          disabled={password.length === 0}
        />
      </div>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    ...DIMENS.common.centering,
    flexDirection: "column",
    height: "100%",
  },
  innerContainer: {
    width: 300,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

export default UpdatePasswordScreen;

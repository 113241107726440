import gql from "graphql-tag";

export const customerQuery = gql`
  query customer($id: ID) {
    customer(id: $id) {
      id
      companyName
      employeesCount {
        title
        id
      }
      coverImage
      logo
      accentColor {
        id
        main
        secondary
      }
      branch {
        id
        title
      }
      employeeGroups {
        id
        type {
          id
          title
        }
        surveys {
          id
          infoTranslations {
            language {
              id
            }
            title
            description
          }
          infoTranslation {
            title
            description
            id
          }
          surveyTime {
            translations {
              language {
                id
              }
              title
            }
            translation {
              title
            }
          }
          link
        }
      }
    }
  }
`;

export const branchesQuery = gql`
  query {
    branches {
      title
      id
    }
  }
`;

export const languagesQuery = gql`
  query {
    languages {
      title
      id
    }
  }
`;

export const accentColorsQuery = gql`
  query {
    accentColors {
      main
      secondary
      id
    }
  }
`;

export const employeesCountsQuery = gql`
  query {
    employeesCounts {
      title
      id
    }
  }
`;

export const customersQuery = gql`
  query {
    customers {
      id
      companyName
      employeesCount {
        id
        title
      }
      branch {
        title
      }
      accentColor {
        main
        secondary
      }
    }
  }
`;

export const surveyQuery = gql`
  query survey($id: ID) {
    survey(id: $id) {
      surveyTime {
        id
        translations {
          language {
            id
          }
          title
        }
        translation {
          language {
            id
          }
          title
        }
      }
      infoTranslation {
        language {
          id
        }
        title
        description
      }
      id
      link
      infoTranslation {
        id
        description
        title
      }
      questions {
        scale
        id
        kpi {
          translation {
            language {
              id
            }
            title
          }
        }
        isActive
        answerType {
          title
          id
        }
        answerAlternatives {
          translation {
            id
            title
          }
          id
          translation {
            id
            title
          }
        }
        translation {
          id
          question {
            id
          }
          language {
            id
            title
          }
          kpiDescription
          title
        }
      }
    }
  }
`;

export const surveyByLinkQuery = gql`
  query surveyByLink($link: String) {
    surveyByLink(link: $link) {
      surveyTime {
        translation {
          language {
            id
          }
          title
        }
        id
      }
      employeeGroup {
        id
        type {
          id
          title
        }
        customer {
          companyName
          id
          coverImage
          logo
          accentColor {
            main
            secondary
            id
          }
        }
      }
      id
      link
      infoTranslation {
        language {
          id
          title
        }
        id
        description
        title
      }
      questions {
        id
        kpi {
          translation {
            language {
              id
            }
            title
          }
        }
        answerType {
          title
          id
        }
        answerAlternatives {
          id
          translation {
            id
            title
            language {
              id
              title
            }
          }
        }
        translation {
          id
          question {
            id
          }
          language {
            id
            title
          }
          kpiDescription
          title
        }
      }
    }
  }
`;

export const rowsQuery = gql`
  query rows($customerIds: [Int], $kpiIds: [Int], $surveyTimeIds: [Int]) {
    rows(
      customerIds: $customerIds
      kpiIds: $kpiIds
      surveyTimeIds: $surveyTimeIds
    ) {
      rows
    }
  }
`;

export const kpisQuery = gql`
  query {
    kpis {
      id
      translation {
        language {
          id
        }
        id
        title
      }
    }
  }
`;

export const surveyTimesQuery = gql`
  query {
    surveyTimes {
      id
      translation {
        language {
          id
        }
        title
      }
    }
  }
`;

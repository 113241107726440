import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Button, Input, Modal, Spacer, Text } from "../../common";
import { SPACING } from "../../constants";
import { translate } from "../../i18n";
import { useAccentColors, useLanguages } from "../../hooks";
import Loader from "../Loader/Loader";
import AccentColorList from "../AccentColorList/AccentColorList";

const translateKey = "addKpiModal.";

const AddKpiModal = ({ isOpen, onClose, onCreateKPI }) => {
  const [languages, languagesLoading] = useLanguages();
  const [accentColors, accentColorsLoading] = useAccentColors();
  const classes = useStyles();
  const [accentColor, setAccentColor] = useState(accentColors[0]);
  const [translations, setTranslations] = useState({});

  useEffect(() => {
    if (accentColors.length > 0 && !accentColor) {
      setAccentColor(accentColors[0]);
    }
  }, [accentColors, accentColor]);

  const onInputTranslationChange = (languageId, newValue) => {
    setTranslations((p) => ({ ...p, [languageId]: newValue }));
  };

  const handleSaveButtonClick = () => {
    onCreateKPI({
      translations: Object.entries(translations).map(([languageId, title]) => ({
        languageId: parseInt(languageId),
        title,
      })),
      accentColorId: accentColor.id,
    });
  };

  const saveIsDisabled = () =>
    languages.some(
      ({ id }) => !(id in translations) || translations[id] === ""
    ) || !accentColor;

  if (
    languagesLoading ||
    languages.length === 0 ||
    accentColorsLoading ||
    accentColors.length === 0
  ) {
    <Loader />;
  }
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className={classes.container}>
        <Text variant="h3">{translate(translateKey + "header")}</Text>
        <Spacer spacing="medium" />
        {languages.map((language) => (
          <div className={classes.translationInputContainer}>
            <Text variant="caption" color="grey60">
              {language.title}
            </Text>

            <Input
              className={classes.input}
              placeholder={language.title}
              value={translations[language.id] || ""}
              onChange={(v) => onInputTranslationChange(language.id, v)}
            />
            <Spacer spacing="small" />
          </div>
        ))}
        <Spacer spacing="large" />
        <Text
          style={{ alignSelf: "flex-start" }}
          variant="body"
          bold
          color="black"
        >
          {translate(translateKey + "accentColorHeader")}
        </Text>
        <Spacer spacing="large" />
        <AccentColorList
          colors={accentColors}
          value={accentColor}
          onChange={setAccentColor}
        />
        <Spacer spacing="extraLarge" />
        <Button
          title={translate(translateKey + "addButton")}
          style={classes.addButton}
          onClick={handleSaveButtonClick}
          disabled={saveIsDisabled()}
        />
      </div>
    </Modal>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: SPACING.medium,
    paddingLeft: 40,
    paddingRight: 40,
    textAlign: "center",
  },
  addButton: {
    alignSelf: "center",
  },
  translationInputContainer: {
    flexDirection: "column",
    alignItems: "flex-start",
    display: "flex",
    width: "100%",
  },
}));

export default AddKpiModal;

const Warning = ({ fill, iconSize, style }) => (
  <svg
    width={iconSize}
    height={iconSize}
    viewBox="0 0 18 18"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.00033 4.83334C9.45866 4.83334 9.83366 5.20834 9.83366 5.66667V9.00001C9.83366 9.45834 9.45866 9.83334 9.00033 9.83334C8.54199 9.83334 8.16699 9.45834 8.16699 9.00001V5.66667C8.16699 5.20834 8.54199 4.83334 9.00033 4.83334ZM8.99199 0.666672C4.39199 0.666672 0.666992 4.40001 0.666992 9.00001C0.666992 13.6 4.39199 17.3333 8.99199 17.3333C13.6003 17.3333 17.3337 13.6 17.3337 9.00001C17.3337 4.40001 13.6003 0.666672 8.99199 0.666672ZM9.00033 15.6667C5.31699 15.6667 2.33366 12.6833 2.33366 9.00001C2.33366 5.31667 5.31699 2.33334 9.00033 2.33334C12.6837 2.33334 15.667 5.31667 15.667 9.00001C15.667 12.6833 12.6837 15.6667 9.00033 15.6667ZM9.83366 13.1667H8.16699V11.5H9.83366V13.1667Z"
      fill={fill}
    />
  </svg>
);

export default Warning;

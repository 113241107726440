import React from "react";
import { makeStyles, Radio } from "@material-ui/core";
import theme from "../../theme";

const ITEM_SIZE = { width: 20, height: 20 };

const RadioButton = ({
  checked,
  onClick,
  disabled,
  checkedColor = theme.palette.grey[80],
  ...props
}) => {
  const classes = useStyles();
  return (
    <>
      <div
        className={classes.outerContainer}
        style={{
          borderColor: checked && checkedColor,
          cursor: !disabled && "pointer",
        }}
        onClick={!disabled && onClick}
        {...props}
      >
        {checked && (
          <div
            className={classes.innerContainer}
            style={{ backgroundColor: checked && checkedColor }}
          />
        )}
      </div>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  outerContainer: {
    borderRadius: "50%",
    ...ITEM_SIZE,
    borderColor: theme.palette.grey[80],
    borderWidth: 2,
    borderStyle: "solid",
    padding: 3,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  innerContainer: {
    borderRadius: "50%",
    width: "100%",
    height: "100%",
    backgroundColor: theme.palette.grey[80],
  },
}));

export default RadioButton;

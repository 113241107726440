import React from "react";
import { makeStyles, Popover as MUIPopover } from "@material-ui/core";
import Icon from "../Icon/Icon";
import Text from "../Text/Text";
import { DIMENS, SPACING } from "../../constants";
import Spacer from "../Spacer/Spacer";

const ITEM_WIDTH = 200;

const PopoverItem = ({ onClick, icon, text, iconSize }) => {
  const classes = useStyles();

  return (
    <div className={classes.popoverItemRoot} onClick={onClick}>
      <Icon name={icon} size={iconSize} />
      <Spacer spacing="medium" direction="horizontal" />
      <Text variant="caption" color="grey90">
        {text}
      </Text>
    </div>
  );
};

const Popover = ({ anchorEl, setAnchorEl, items }) => {
  const classes = useStyles();
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <MUIPopover
      anchorEl={anchorEl}
      open={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      PaperProps={{
        className: classes.popoverRoot,
      }}
    >
      {items.map(
        ({ onClick, icon = "dots", text, iconSize = "medium" }, index) => (
          <PopoverItem
            key={index.toString()}
            onClick={() => {
              onClick();
              handleClose();
            }}
            icon={icon}
            text={text}
            iconSize={iconSize}
          />
        )
      )}
    </MUIPopover>
  );
};

const useStyles = makeStyles((theme) => ({
  popoverItemRoot: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingLeft: SPACING.large,
    width: ITEM_WIDTH,
    cursor: "pointer",
    borderBottomColor: theme.palette.grey[5],
    borderBottomStyle: "solid",
    borderBottomWidth: 1,
    paddingTop: SPACING.small,
    paddingBottom: SPACING.small,
  },
  popoverRoot: {
    borderRadius: DIMENS.common.borderRadius,
    boxShadow: "0px 0px 20px 0px #0000000D;",
    borderColor: theme.palette.grey[30],
    borderWidth: 1,
    borderStyle: "solid",
  },
}));

export default Popover;

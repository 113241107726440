const Success = ({ fill, iconSize, style }) => (
  <svg
    width={iconSize}
    height={iconSize}
    viewBox="0 0 18 18"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.00033 0.666672C4.40033 0.666672 0.666992 4.40001 0.666992 9.00001C0.666992 13.6 4.40033 17.3333 9.00033 17.3333C13.6003 17.3333 17.3337 13.6 17.3337 9.00001C17.3337 4.40001 13.6003 0.666672 9.00033 0.666672ZM9.00033 15.6667C5.32533 15.6667 2.33366 12.675 2.33366 9.00001C2.33366 5.32501 5.32533 2.33334 9.00033 2.33334C12.6753 2.33334 15.667 5.32501 15.667 9.00001C15.667 12.675 12.6753 15.6667 9.00033 15.6667ZM12.2337 5.90834L7.33366 10.8083L5.76699 9.24167C5.44199 8.91667 4.91699 8.91667 4.59199 9.24167C4.26699 9.56667 4.26699 10.0917 4.59199 10.4167L6.75033 12.575C7.07533 12.9 7.60033 12.9 7.92533 12.575L13.417 7.08334C13.742 6.75834 13.742 6.23334 13.417 5.90834C13.092 5.58334 12.5587 5.58334 12.2337 5.90834Z"
      fill={fill}
    />
  </svg>
);

export default Success;

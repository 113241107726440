import React from "react";
import { makeStyles, Tab as MUITab, Tabs as MUITabs } from "@material-ui/core";
import { DIMENS, SPACING } from "../../constants";

const Tabs = ({ labels, value, onChange }) => {
  const classes = useStyles();

  const handleChange = (event, newValue) => {
    onChange(newValue);
  };

  return (
    <div>
      <MUITabs
        value={value}
        onChange={handleChange}
        TabIndicatorProps={{ className: classes.indicator }}
      >
        {labels.map((label, index) => (
          <MUITab
            key={index.toString()}
            label={label}
            className={classes.labelContainer}
            style={{ marginLeft: index > 0 ? SPACING.medium : 0 }}
            classes={{
              labelContainer: classes.labelContainer,
              root: classes.tabRoot,
            }}
          />
        ))}
      </MUITabs>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  indicator: {
    backgroundColor: theme.palette.primary.tintDark,
    height: 3,
    borderRadius: DIMENS.common.borderRadius,
  },
  labelContainer: {
    fontSize: 16,
    fontWeight: 400,
    color: theme.palette.grey[90],
    padding: 0,
    paddingLeft: SPACING.medium,
    paddingRight: SPACING.medium,
  },
  tabRoot: {
    minWidth: 0,
  },
  tabPanel: {
    width: "100%",
    height: "100%",
  },
}));

export default Tabs;

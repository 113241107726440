/* eslint-disable no-unused-vars */
import React from "react";
import { Navigate, Route, Routes as DOMRoutes } from "react-router-dom";
import {
  CreateCustomerScreen,
  CustomerScreen,
  ForgotPasswordScreen,
  MainScreen,
  SurveyScreen,
  SurveySentScreen,
  UpdatePasswordScreen,
  UpdateSurveyScreen,
} from "../screens";
import CreateQuestionScreen from "../screens/CreateQuestionScreen/CreateQuestionScreen";

const MainRoutes = () => {
  return (
    <DOMRoutes>
      <Route path="/">
        <Route path="/" element={<Navigate to="app" replace />} />
        <Route path="/app">
          <Route path="" element={<MainScreen />} />
          <Route path="customer">
            <Route path=":customerId">
              <Route path="" element={<CustomerScreen />} />
              <Route path="survey">
                <Route path=":surveyId">
                  <Route path="" element={<UpdateSurveyScreen />} />
                  <Route
                    path="create-question"
                    element={<CreateQuestionScreen />}
                  />
                </Route>
              </Route>
            </Route>
            <Route path="create" element={<CreateCustomerScreen />} />
          </Route>
        </Route>
        <Route path="/reset-password" element={<ForgotPasswordScreen />} />
        <Route path="/update-password" element={<UpdatePasswordScreen />} />
        <Route path="/forms">
          <Route path=":link">
            <Route path="" element={<SurveyScreen />} />
            <Route path="sent" element={<SurveySentScreen />} />
          </Route>
        </Route>
        <Route path="/*" element={<Navigate to="app" replace />} />
      </Route>
    </DOMRoutes>
  );
};

export default MainRoutes;

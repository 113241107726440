import { makeStyles } from "@material-ui/core";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import { AuthRoutes, MainRoutes } from "./navigation";
import { useAuth } from "./providers/AuthProvider";

const App = () => {
  const { currentUser } = useAuth();

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ToastContainer position="top-center" autoClose={false} />
      <Router>{currentUser ? <MainRoutes /> : <AuthRoutes />}</Router>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    position: "absolute",
  },
}));

export default App;

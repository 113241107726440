import { makeStyles } from "@material-ui/core";
import React from "react";
import { Spacer, Text } from "../../common";
import Logo from "../Logo/Logo";

const Slogan = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Text variant="h3" bold color="secondary" style={{ marginTop: -3 }}>
        Powered by
      </Text>
      <Spacer direction="horizontal" spacing="small" />
      <Logo size="small" />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
}));

export default Slogan;

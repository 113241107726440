import { makeStyles } from "@material-ui/core";
import React from "react";
import { IconButton, Spacer, Text, Tooltip } from "../../common";
import { SPACING } from "../../constants";
import { translate } from "../../i18n";
import theme from "../../theme";

import FloatingContainer from "../FloatingContainer/FloatingContainer";
import RadioButtonList from "../RadioButtonList/RadioButtonList";

const QuestionCard = ({
  question,
  survey,
  languageId,
  value,
  onChange,
  accentColor,
}) => {
  const classes = useStyles();
  const translateKey = "questionCard.";
  const questionTranslated = question.translation;
  const answerAlternativesTranslated = question.answerAlternatives.map(
    (alternative) => ({
      id: alternative.id,
      title: alternative?.translation?.title,
    })
  );

  const kpiTranslated = question.kpi.translation;

  const getRadioButtonListDirection = () => {
    switch (question.answerType.id) {
      case 1:
        return "vertical";
      case 2:
        return "horizontal";
      default:
        break;
    }
  };

  return (
    <FloatingContainer className={classes.container}>
      <div className={classes.upperContainer}>
        <div className={classes.kpiContainer}>
          <Text variant="smallText">{kpiTranslated.title}</Text>
        </div>
        {survey.surveyTime.translation.title !== "Offboarding" && (
          <Tooltip text={questionTranslated.kpiDescription}>
            <div className={classes.iconButton}>
              <IconButton
                icon="info"
                iconSize="large"
                iconColor={accentColor}
              />
            </div>
          </Tooltip>
        )}
      </div>
      <Spacer spacing="small" />
      <Text>{questionTranslated.title}</Text>
      <Spacer spacing="large" />
      <RadioButtonList
        checkedColor={accentColor}
        direction={getRadioButtonListDirection()}
        items={answerAlternativesTranslated}
        value={value}
        onChange={onChange}
        rightText={
          question.answerType.id === 2 &&
          translate(translateKey + "highestRatingText")
        }
        leftText={
          question.answerType.id === 2 &&
          translate(translateKey + "lowestRatingText")
        }
      />
    </FloatingContainer>
  );
};

const useStyles = makeStyles(() => ({
  upperContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    width: "100%",
  },
  kpiContainer: {
    paddingTop: SPACING.tiny,
    paddingBottom: SPACING.tiny,
    paddingLeft: SPACING.medium,
    paddingRight: SPACING.medium,
    borderRadius: 100,
    backgroundColor: theme.palette.grey[5],
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
}));

export default QuestionCard;

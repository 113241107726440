import { makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import {
  Button,
  Dropdown,
  IconButton,
  Input,
  Spacer,
  Text,
} from "../../common";
import { DIMENS } from "../../constants";
import { translate } from "../../i18n";

const translateKey = "questionDetailsView.";

const QuestionDetailsView = ({
  kpiChoices,
  answerTypeChoices,
  fields,
  onFieldsChange,
  languages,
  header,
}) => {
  const classes = useStyles();

  const updateFields = (updateObject) => {
    onFieldsChange((prev) => ({ ...prev, ...updateObject(prev) }));
  };

  const handleAddAnswerAlternative = (newAnswerAlternative) => {
    updateFields((prev) => ({
      answerAlternatives: [...prev.answerAlternatives, newAnswerAlternative],
    }));
  };

  const handleRemoveAnswerAlternative = (index) => {
    updateFields((prev) => ({
      answerAlternatives: prev.answerAlternatives.filter((_, i) => i !== index),
    }));
  };

  return (
    <div className={classes.root}>
      <Text variant="h2" bold color="black">
        {header}
      </Text>
      <Spacer spacing="medium" />
      <Text variant="h3" bold color="black">
        {translate(translateKey + "subheader")}
      </Text>
      <Spacer spacing="medium" />
      <Dropdown
        placeholder={translate(translateKey + "placeholderKPI")}
        items={kpiChoices}
        value={fields.kpiDropdownObject}
        onChange={(updated) =>
          updateFields(() => ({ kpiDropdownObject: updated }))
        }
      />
      <Spacer spacing="medium" />
      <Dropdown
        placeholder={translate(translateKey + "placeholderAnswerType")}
        items={answerTypeChoices}
        value={fields.answerTypeDropdownObject}
        onChange={(updated) =>
          updateFields(() => ({ answerTypeDropdownObject: updated }))
        }
      />
      <Spacer spacing="extraLarge" />
      {fields.answerTypeDropdownObject.isScale ? (
        <></> //TODO: UI to update the scale
      ) : (
        <div>
          <Text variant="h3" bold color="black">
            {translate(translateKey + "answerAlternativesHeader")}
          </Text>
          <Spacer spacing="medium" />
          <AnswerAlternativeSection
            languages={languages}
            answerAlternatives={fields.answerAlternatives}
            onAddAnswerAlternative={handleAddAnswerAlternative}
            onRemoveAnswerAlternative={handleRemoveAnswerAlternative}
          />
        </div>
      )}
    </div>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    width: "70%",
  },
}));

const AnswerAlternativeSection = ({
  answerAlternatives,
  onAddAnswerAlternative,
  onRemoveAnswerAlternative,
  languages,
}) => {
  const languageTitleById = (id) =>
    languages.find(({ id: langId }) => langId === id).title;

  const [newAnswerAlternative, setNewAnswerAlternative] = useState({
    translations: [],
  });

  const isAddDisabled = () =>
    !newAnswerAlternative ||
    newAnswerAlternative.translations.length !== languages.length ||
    !newAnswerAlternative.translations.every(
      (t) => t && t.title !== "" && t.languageId
    );

  const handleAddAnswerAlternative = () => {
    onAddAnswerAlternative(newAnswerAlternative);
    setNewAnswerAlternative({ translations: [] });
  };

  const handleInputChange = (newValue, lang) => {
    setNewAnswerAlternative((prev) => ({
      ...prev,
      translations: [
        ...prev.translations.filter(({ languageId }) => languageId !== lang.id),
        { languageId: lang.id, title: newValue },
      ],
    }));
  };

  const classes = useStylesAnswerAlternativeSection();

  return (
    <div>
      {answerAlternatives.map(({ translations }, i) => (
        <>
          <div className={classes.answerAlternativeContainer}>
            <div>
              {translations.map(({ title, languageId }) => (
                <div className={classes.answerAlternativeInnerContainer}>
                  <Text variant="caption" color="grey60">
                    {languageTitleById(languageId)}
                  </Text>
                  <Spacer spacing="small" direction="horizontal" />
                  <Text>{title}</Text>
                </div>
              ))}
            </div>
            <IconButton
              icon="remove"
              onClick={() => {
                onRemoveAnswerAlternative(i);
              }}
            />
          </div>
          <Spacer spacing="small" />
        </>
      ))}
      {answerAlternatives.translations?.length >= 0 && (
        <Spacer spacing="extraLarge" />
      )}
      <div>
        {languages.map((lang) => (
          <div>
            <Text variant="caption" color="grey90">
              {lang.title}
            </Text>
            <Input
              placeholder={lang.title}
              value={
                newAnswerAlternative.translations.find(
                  ({ languageId }) => languageId === lang.id
                )?.title || ""
              }
              onChange={(newValue) => handleInputChange(newValue, lang)}
            />
          </div>
        ))}
      </div>
      <Spacer />
      <Button
        title={translate(translateKey + "addAnswerAlternativeButton")}
        icon="plusCircle"
        disabled={isAddDisabled()}
        onClick={handleAddAnswerAlternative}
      />
    </div>
  );
};

const useStylesAnswerAlternativeSection = makeStyles((theme) => ({
  answerAlternativeInnerContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  answerAlternativeContainer: {
    flexDirection: "row",
    display: "flex",
    alignItems: "space-between",
    width: "100%",
    justifyContent: "space-between",
    borderColor: theme.palette.grey[5],
    paddingLeft: DIMENS.common.marginSmall,
    paddingRight: DIMENS.common.marginSmall,
    borderWidth: 1,
    borderRadius: DIMENS.common.borderRadius,
    borderStyle: "solid",
  },
}));

export default QuestionDetailsView;

import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Input, Snackbar, Spacer, Text } from "../../common";
import { Logo } from "../../components";
import { translate } from "../../i18n";
import { useAuth } from "../../providers/AuthProvider";

const IMAGE_PATH = "./images/loginScreenImage.png";

const LoginScreen = () => {
  const classes = useStyles();
  const { logIn } = useAuth();

  const translateKey = "loginScreen.";
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showSnackbar, setShowSnackbar] = useState(false);

  const [isError, setIsError] = useState(false);

  const handleLogin = async () => {
    setIsError(false);
    const res = await logIn(email, password);
    if (!res) {
      setIsError(true);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && !loginButtonIsDisabled) {
      handleLogin();
    }
  };

  useEffect(() => {
    if (isError) {
      setIsError(false);
    }
  }, [email, password]);

  useEffect(() => {
    if (isError) {
      setShowSnackbar(true);
    }
  }, [isError]);

  const loginButtonIsDisabled = email.length === 0 || password.length === 0;

  return (
    <div className={classes.root}>
      <div className={classes.leftContainer}>
        <div className={classes.contentContainer}>
          <Logo />
          <Spacer spacing="extraLarge" />
          <Text variant="h3" color="primary">
            {translate(translateKey + "subheader")}
          </Text>
          <Spacer spacing="large" />

          <Input
            name="email"
            error={isError}
            placeholder={translate(translateKey + "emailPlaceholder")}
            icon="email"
            value={email}
            onChange={setEmail}
          />
          <Spacer spacing="large" />
          <Input
            name="password"
            error={isError}
            value={password}
            onChange={setPassword}
            placeholder={translate(translateKey + "passwordPlaceholder")}
            icon="password"
            type="password"
            onKeyPress={handleKeyPress}
          />
          <Spacer spacing="small" />
          <div className={classes.buttonContainer}>
            <Link to="/forgot-password" className={classes.forgotPasswordLink}>
              <Text variant="body" bold color="primary">
                {translate(translateKey + "forgotPassword")}
              </Text>
            </Link>
            <Spacer spacing="extraLarge" />

            <Button
              disabled={loginButtonIsDisabled}
              title={translate(translateKey + "loginButton")}
              onClick={handleLogin}
            />
          </div>

          <Spacer spacing="extraLarge" />
          <Snackbar
            type="error"
            text={translate(translateKey + "error")}
            isVisible={showSnackbar}
            setIsVisible={setShowSnackbar}
          />
        </div>
      </div>
      <div className={classes.rightContainer} />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: "100%",
  },
  leftContainer: {
    width: "50%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
  rightContainer: {
    width: "50%",
    height: "100%",
    overflow: "hidden",
    backgroundImage: `url(${IMAGE_PATH})`,
    backgroundSize: "cover",
  },
  contentContainer: {
    width: "70%",
    maxWidth: 600,
  },
  image: {
    height: "100%",
    width: "100%",
    objectFit: "cover",
  },
  buttonContainer: {
    display: "flex",
    alignItems: "flex-end",
    width: "100%",
    flexDirection: "column",
  },
  forgotPasswordLink: {
    textDecoration: "none",
  },
}));

export default LoginScreen;

import { makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import { Icon, Spacer, Text } from "../../common";
import { DIMENS, SPACING } from "../../constants";

const DIV_STATES = {
  OPEN: "open",
  CLOSED: "closed",
};

const DIV_HEIGHT_CLOSED = 45;

const getDefaultDivStates = (items) => {
  const divStates = Array(items.length).fill(DIV_STATES.CLOSED);
  divStates[0] = DIV_STATES.OPEN;
  return divStates;
};

const DropdownMultiple = ({ items = [{}] }) => {
  //item has {title, icon, Component}
  const classes = useStyles();
  const [divStates, setDivStates] = useState(getDefaultDivStates(items));

  const handleOnClick = (index) => {
    let divStatesCopy = [...divStates];
    if (divStates[index] === DIV_STATES.CLOSED) {
      divStatesCopy = Array(items.length).fill(DIV_STATES.CLOSED);
      divStatesCopy[index] = DIV_STATES.OPEN;
      setDivStates(divStatesCopy);
      return;
    }
    divStatesCopy[index] = DIV_STATES.CLOSED;
    setDivStates(divStatesCopy);
  };

  return (
    <>
      {items.map(({ icon, title, Component }, index) => (
        <div key={index.toString()}>
          {index !== 0 && <Spacer spacing="medium" />}
          <div
            className={classes.itemContainer}
            style={{
              height:
                divStates[index] === DIV_STATES.CLOSED && DIV_HEIGHT_CLOSED + 2,
            }}
          >
            <div
              className={classes.titleContainer}
              onClick={() => handleOnClick(index)}
            >
              <Icon name={icon} />
              <Spacer spacing="small" direction="horizontal" />
              <Text variant="subHeader" bold color="grey80">
                {title}
              </Text>
            </div>
            <Component />
          </div>
        </div>
      ))}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  itemContainer: {
    backgroundColor: theme.palette.white,
    width: "100%",
    overflow: "hidden",
    //transition: "height 0.15s ease-in",
    ...DIMENS.common.border,
    borderRadius: DIMENS.common.borderRadius,
    paddingLeft: SPACING.medium,
    paddingRight: SPACING.medium,
    paddingBottom: SPACING.large,
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    height: DIV_HEIGHT_CLOSED,
    cursor: "pointer",
    width: "100%",
  },
}));

export default DropdownMultiple;

import { makeStyles } from "@material-ui/core";
import React from "react";
import theme from "../../theme";
import {
  Back,
  Close,
  Dots,
  Email,
  Photo,
  Password,
  Remove,
  Success,
  Upload,
  Warning,
  ArrowRight,
  PasswordSolid,
  LogOut,
  Users,
  Link,
  EN,
  SV,
  Check,
  File,
  PlusCircle,
  Info,
  Download,
} from "./icons";

const ICON_SIZE = {
  small: 10,
  medium: 15,
  large: 20,
  extraLarge: 28,
};

const ICON_PATH = {
  password: Password,
  email: Email,
  success: Success,
  warning: Warning,
  close: Close,
  back: Back,
  upload: Upload,
  dots: Dots,
  remove: Remove,
  photo: Photo,
  arrowRight: ArrowRight,
  passwordSolid: PasswordSolid,
  logOut: LogOut,
  users: Users,
  link: Link,
  en: EN,
  sv: SV,
  check: Check,
  file: File,
  plusCircle: PlusCircle,
  info: Info,
  download: Download,
};

const getIconSize = (size) => {
  if (typeof size === "number") {
    return size;
  }
  return ICON_SIZE[size] || ICON_SIZE.medium;
};

const Icon = ({
  name,
  size = "medium",
  fill = theme.palette.grey[90],
  style,
}) => {
  const classes = useStyles();
  const iconSize = getIconSize(size);

  const SvgComponent = ICON_PATH[name];
  return (
    <div style={style} className={classes.root}>
      <SvgComponent fill={fill} iconSize={iconSize} />
    </div>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default Icon;

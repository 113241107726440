import gql from "graphql-tag";
import { objectToGraphql } from "./helpers";

export const createCustomerMutation = (obj) => {
  const input = objectToGraphql(obj);
  return gql`
   mutation {
   createCustomer(customer: ${input}) {id
  }
  }
`;
};

export const createQuestionMutation = (obj) => {
  const input = objectToGraphql(obj);
  return gql`
   mutation {
   createQuestion(question: ${input}) {id
  }
  }
`;
};

export const updateCustomerMutation = (obj) => {
  const input = objectToGraphql(obj);
  return gql`
   mutation {
   updateCustomer(customer: ${input}) {id
   }
  }
`;
};

export const deleteCustomerMutation = (id) => {
  return gql`
   mutation {
   deleteCustomer(id: ${id}) {id
   }
  }
`;
};

export const updateSurveyInfoTranslationMutation = (obj) => {
  const input = objectToGraphql(obj);
  return gql`
  mutation {updateSurveyInfoTranslation(infoTranslation: ${input}) {
    id
    
  }}`;
};

export const updateQuestionTranslationsMutation = (lst) => {
  const input = objectToGraphql(lst);
  return gql`
  mutation {updateQuestionTranslations(translations: ${input}) {
    id
    
  }}`;
};

export const updateQuestionsMutation = (lst) => {
  const input = objectToGraphql(lst);
  return gql`
  mutation {updateQuestions(questions: ${input}) {
    id 
  }}`;
};

export const createAnswersMutation = gql`
  mutation createAnswers($answers: [AnswerInput], $surveyId: Int) {
    createAnswers(answers: $answers, surveyId: $surveyId) {
      id
    }
  }
`;

export const createKPIMutation = gql`
  mutation createKPI($kpi: KPIInput) {
    createKPI(kpi: $kpi) {
      id
    }
  }
`;

export const signUpMutation = gql`
  mutation signUp($email: String, $password: String) {
    signUp(email: $email, password: $password) {
      token
      user {
        id
        email
      }
    }
  }
`;

export const signInMutation = gql`
  mutation signIn($email: String!, $password: String!) {
    signIn(email: $email, password: $password) {
      token
      user {
        id
        email
      }
    }
  }
`;

export const authenticateMutation = gql`
  mutation {
    authenticate {
      token
      user {
        id
        email
      }
    }
  }
`;

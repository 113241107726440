import i18n from "i18n-js";

const en = require("./locales/en.json");

const sv = require("./locales/sv.json");

i18n.fallbacks = true;

i18n.translations = {
  en,
  sv,
};

const fallback = { languageTag: "sv", isRTL: false };
i18n.locale = fallback.languageTag;

export default i18n;

import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  createQuestion,
  getKpis,
  getLanguages,
} from "../../api/graphql/requests";
import { Spacer } from "../../common";
import { Header, LoadingContainer } from "../../components";
import { DEFAULT_LANGUAGE_ID, MAX_WIDTH } from "../../constants";
import { QuestionDetailsView } from "../../views";
import { translate } from "../../i18n";

const translateKey = "createQuestionScreen.";

const getAnswerTypes = () => {
  return [
    { id: 1, title: translate(translateKey + "answerTypeChoice") },
    {
      id: 2,
      title: translate(translateKey + "answerTypeScale"),
      isScale: true,
    },
  ];
};

const CreateQuestionScreen = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { surveyId } = useParams();

  const [loading, setLoading] = useState(false);
  const [kpiChoices, setKpiChoices] = useState();
  const [answerTypeChoices, setAnswerTypeChoices] = useState();
  const [languages, setLanguages] = useState();
  const [questionFields, setQuestionFields] = useState();

  useEffect(() => {
    (async () => {
      const kpiChoices = await getKpis(DEFAULT_LANGUAGE_ID);
      setKpiChoices(
        kpiChoices.map(({ translation: { title }, id }) => ({ title, id }))
      );
      const languages = await getLanguages();
      setLanguages(languages);

      const answerTypeChoices = getAnswerTypes();
      setAnswerTypeChoices(answerTypeChoices);
    })();
  }, []);

  useEffect(() => {
    if (answerTypeChoices) {
      let newFields = {
        kpiDropdownObject: undefined,
        answerTypeDropdownObject: answerTypeChoices[0],
        answerAlternatives: [],
        scale: [0, 10], //TODO: allow scale to be dynamic and changed in QuestionDetailsView
      };
      setQuestionFields(newFields);
    }
  }, [answerTypeChoices]);

  const handleSaveQuestion = async () => {
    const questionToPost = {
      surveyId: Number(surveyId),
      kpiId: questionFields.kpiDropdownObject.id,
      answerTypeId: questionFields.answerTypeDropdownObject.id,
      answerAlternatives: questionFields.answerAlternatives,
      scale:
        questionFields.answerTypeDropdownObject.isScale && questionFields.scale,
      translations: [
        {
          languageId: 1,
          title: "Någon titel",
          kpiDescription: "Någon kpi-beskrivning",
        },
        {
          languageId: 2,
          title: "Some title",
          kpiDescription: "Some kpi-description",
        },
      ], //TODO: hard-coded translations for now, they can be changed in the SurveyScreen until we fix
    };

    const res = await createQuestion(questionToPost);
    if (!res) {
      setLoading(false);
      return;
    }
    setLoading(false);
    navigate(-1, { replace: true }); //TODO: navigate -1 is not good
  };

  const someFieldsAreEmpty = () => {
    const emptyValues = [undefined, null, [], {}, "", false];

    return Object.values(questionFields).some((el) => {
      return emptyValues.includes(el);
    });
  };

  if (!kpiChoices || !answerTypeChoices || !questionFields) {
    return <div />;
  }

  const isSaveDisabled =
    someFieldsAreEmpty() ||
    loading ||
    (!questionFields.answerTypeDropdownObject.isScale &&
      questionFields.answerAlternatives.length === 0);

  return (
    <div className={classes.root}>
      <Header
        ableToGoBack
        buttons={[
          {
            onClick: handleSaveQuestion,
            title: translate(translateKey + "saveQuestionButton"),
            disabled: isSaveDisabled,
            icon: "plusCircle",
          },
        ]}
      />

      <Spacer spacing="large" />
      {loading ? (
        <LoadingContainer />
      ) : (
        <div className={classes.viewContainer}>
          <QuestionDetailsView
            kpiChoices={kpiChoices}
            answerTypeChoices={answerTypeChoices}
            fields={questionFields}
            onFieldsChange={setQuestionFields}
            languages={languages}
          />
        </div>
      )}
    </div>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  viewContainer: {
    width: "90%",
    maxWidth: MAX_WIDTH,
    display: "flex",
    justifyContent: "center",
  },
}));

export default CreateQuestionScreen;

import { makeStyles } from "@material-ui/core";
import React from "react";
import { Spacer } from "../../common";
import { Loader, Logo } from "../../components";
import { DIMENS } from "../../constants";

const LoadingView = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Logo size="large" />
      <Spacer spacing="large" />
      <Loader />
    </div>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    height: "100%",
    ...DIMENS.common.centering,
    flexDirection: "column",
    position: "absolute",
  },
}));

export default LoadingView;

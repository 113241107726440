const SV = ({ fill, iconSize, style }) => (
  <svg
    width={iconSize}
    height={iconSize}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
      fill="#FFDA44"
    />
    <path
      d="M6.26077 6.95653H15.9321C15.4208 3.03131 12.0645 0 7.99987 0C7.40255 0 6.82071 0.0660313 6.26074 0.190156V6.95653H6.26077Z"
      fill="#0052B4"
    />
    <path
      d="M4.17357 6.95656V0.972778C1.97688 2.17134 0.404352 4.36981 0.0673828 6.95659H4.17357V6.95656Z"
      fill="#0052B4"
    />
    <path
      d="M4.17354 9.04346H0.0673828C0.404352 11.6302 1.97688 13.8287 4.17357 15.0272L4.17354 9.04346Z"
      fill="#0052B4"
    />
    <path
      d="M6.26074 9.04349V15.8098C6.82071 15.934 7.40255 16 7.99987 16C12.0645 16 15.4208 12.9687 15.9321 9.04346H6.26074V9.04349Z"
      fill="#0052B4"
    />
  </svg>
);

export default SV;

import React from "react";
import { makeStyles, Tooltip as MUITooltip } from "@material-ui/core";
import Text from "../Text/Text";

const Tooltip = ({ text, children }) => {
  const classes = useStyles();

  return (
    <MUITooltip
      title={<Text variant="caption">{text}</Text>}
      arrow
      placement="right"
      classes={{ tooltip: classes.container, arrow: classes.arrow }}
    >
      {children}
    </MUITooltip>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.white,
    filter: "drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.1));",
  },
  arrow: {
    filter: "drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.1));",
    color: theme.palette.white,
  },
}));

export default Tooltip;

import { makeStyles } from "@material-ui/core";
import React from "react";
import { Button, Dropdown, Spacer, Text } from "../../common";
import { Sheet } from "../../components";
import { translate } from "../../i18n";

const SheetView = ({
  customerDropdownItems,
  surveyTimeDropdownItems,
  kpiDropdownItems,
  onCustomerValuesChange,
  customerValues,
  onKpiValuesChange,
  kpiValues,
  onSurveyTimeValuesChange,
  surveyTimeValues,
  loading,
  onResetFilters,
  onExport,
  rows,
  onSearch,
}) => {
  const translateKey = "sheetView.";
  const classes = useStyles();

  if (!customerDropdownItems || !kpiDropdownItems || !surveyTimeDropdownItems) {
    return <></>;
  }

  return (
    <div className={classes.root}>
      <Text variant="h3" bold color="black">
        {translate(translateKey + "header")}
      </Text>
      <Spacer spacing="small" />
      <div className={classes.upperContainer}>
        <Dropdown
          multiple
          value={customerValues}
          items={customerDropdownItems}
          onChange={onCustomerValuesChange}
          placeholder={translate(translateKey + "companyNamePlaceholder")}
        />
        <Spacer spacing="large" direction="horizontal" />
        <Dropdown
          value={surveyTimeValues}
          multiple
          items={surveyTimeDropdownItems}
          onChange={onSurveyTimeValuesChange}
          placeholder={translate(translateKey + "surveyTimesPlaceholder")}
        />
        <Spacer spacing="large" direction="horizontal" />
        <Dropdown
          value={kpiValues}
          multiple
          items={kpiDropdownItems}
          onChange={onKpiValuesChange}
          placeholder={translate(translateKey + "kpisPlaceholder")}
        />
        <Spacer spacing="large" direction="horizontal" />
        <div className={classes.buttonContainer}>
          <Button
            title={translate(translateKey + "searchButton")}
            disabled={
              customerValues.length === 0 ||
              kpiValues.length === 0 ||
              surveyTimeValues.length === 0
            }
            onClick={onSearch}
          />
          <Button
            title={translate(translateKey + "resetFiltersButton")}
            variant="third"
            icon="remove"
            iconSize="medium"
            onClick={onResetFilters}
            disabled={
              customerValues.length === 0 &&
              kpiValues.length === 0 &&
              surveyTimeValues.length === 0
            }
          />
          <Button
            title={translate(translateKey + "exportButton")}
            icon="download"
            iconSize="medium"
            onClick={() => onExport(rows)}
            disabled={!rows}
          />
        </div>
      </div>
      <Spacer spacing="large" />
      <Sheet
        rows={rows}
        loading={loading}
        placeholderTitle={translate(translateKey + "sheetPlaceholderTitle")}
      />
      <Spacer spacing="large" />
    </div>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  upperContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    flexDirection: "row",
  },
}));

export default SheetView;

import { makeStyles } from "@material-ui/core";
import React from "react";
import { DIMENS } from "../../constants";
import Loader from "../Loader/Loader";

const LoadingContainer = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Loader />
    </div>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    width: "100%",
    height: "100%",
    ...DIMENS.common.centering,
  },
}));

export default LoadingContainer;

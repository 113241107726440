import React from "react";

const IMAGE_PATH = window.location.origin + "/images/logo.png";

const LOGO_SIZE = {
  large: { width: 224, height: 79 },
  medium: { width: 113, height: 40 },
  small: { width: 84, height: 29 },
};

const Logo = ({ size = "large" }) => {
  return <img src={IMAGE_PATH} style={LOGO_SIZE[size]} alt="logo" />;
};

export default Logo;
